import Http from './Http';
import Transformer from './transformer';
import Notify from './notify';

function transformRequest(params) {
  return Transformer.send(params);
}

function transformResponse(params) {
  return Transformer.fetch(params);
}

export const postReq = (url, params, success, failed, formData) => {
  Http.post(url, formData ? params : transformRequest(params))
    .then((res) => {
      return success(transformResponse(res.data.data));
    })
    .catch((err) => {
      const { response } = err;
      if (typeof response !== 'undefined') {
        const statusCode = response.status;
        const data = {
          error: null,
          statusCode,
        };

        if (statusCode === 422) {
          const resetErrors = {
            errors: response.data.error.message,
            replace: false,
            searchStr: '',
            replaceStr: '',
          };
          data.error = Transformer.resetValidationFields(resetErrors);
          Notify.error('Invalid input');
        } else if (statusCode === 401) {
          data.error = response.data.error
            ? response.data.error.message
            : response.statusText;
          Notify.error(data.error);
        } else {
          data.error =
            response.data.message ||
            response.data?.error?.message ||
            response.statusText;
          Notify.error(data.error);
        }

        return failed(data);
      }
      return failed({});
    });
};

export const putReq = (url, params, success, failed) => {
  Http.put(url, transformRequest(params))
    .then((res) => {
      return success(transformResponse(res.data.data));
    })
    .catch((err) => {
      const { response } = err;
      if (typeof response !== 'undefined') {
        const statusCode = response.status;
        const data = {
          error: null,
          statusCode,
        };

        if (statusCode === 422) {
          const resetErrors = {
            errors: response.data.error.message,
            replace: false,
            searchStr: '',
            replaceStr: '',
          };
          data.error = Transformer.resetValidationFields(resetErrors);
          Notify.error('Invalid input');
        } else if (statusCode === 401) {
          data.error = response.data.error
            ? response.data.error.message
            : response.statusText;
          Notify.error(data.error);
        } else {
          data.error = response.data.error
            ? response.data.error.message
            : response.statusText;
          Notify.error(data.error);
        }

        return failed(data);
      }
      return failed({});
    });
};

export const patchReq = (url, params, success, failed) => {
  Http.patch(url, transformRequest(params))
    .then((res) => {
      return success(transformResponse(res.data.data));
    })
    .catch((err) => {
      const { response } = err;
      if (typeof response !== 'undefined') {
        const statusCode = response.status;
        const data = {
          error: null,
          statusCode,
        };

        if (statusCode === 422) {
          const resetErrors = {
            errors: response.data.error.message,
            replace: false,
            searchStr: '',
            replaceStr: '',
          };
          data.error = Transformer.resetValidationFields(resetErrors);
          Notify.error('Invalid input');
        } else if (statusCode === 401) {
          data.error = response.data.error
            ? response.data.error.message
            : response.statusText;
          Notify.error(data.error);
        } else {
          data.error = response.data.error
            ? response.data.error.message
            : response.statusText;
          Notify.error(data.error);
        }

        return failed(data);
      }
      Notify.error('oops!');
      return failed({});
    });
};

export const deleteReq = (url, success, failed) => {
  Http.delete(url)
    .then((res) => {
      if (res.data.data.msg) {
        Notify.success(res.data.data.msg);
      }
      return success(transformResponse(res.data.data));
    })
    .catch((err) => {
      // TODO: handle err
      const { response } = err;
      if (typeof response !== 'undefined') {
        const statusCode = response.status;
        const data = {
          error: response.data.error
            ? response.data.error.message
            : response.statusText,
          statusCode,
        };
        Notify.error(data.error);
        return failed(data);
      }
      Notify.error('oops!');
      return failed({});
    });
};

export const getReq = (url, success, failed) => {
  Http.get(url)
    .then((res) => {
      if (res.data.data.msg) {
        Notify.success(res.data.data.msg);
      }
      return success(transformResponse(res.data.data));
    })
    .catch((err) => {
      const { response } = err;
      if (typeof response !== 'undefined') {
        const statusCode = response.status;
        const data = {
          error: response.data.error
            ? response.data.error.message
            : response.statusText,
          statusCode,
        };
        Notify.error(data.error);
        return failed(data);
      }
      Notify.error('oops!');
      return failed({});
    });
};
