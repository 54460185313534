import axios from 'axios';
import store from '../store';
import Auth from './auth';

export const BASE_URL =
  'https://x0e3s7tqv2.execute-api.eu-west-1.amazonaws.com/Prod/';
// "https://8l4cyxccdh.execute-api.eu-west-1.amazonaws.com/Prod/";
const ADMIN_URL = BASE_URL + 'admin';

const api = axios.create({
  baseURL: ADMIN_URL,
});

api.defaults.headers.common.Accept = 'application/json';

const auth = new Auth();

const apiError = async (error) => {
  if (error.response?.status === 401) {
    if (store?.getState()?.isAuthenticated) {
      auth.refreshSession(() => window.location.reload());
      return;
    }

    window.location.href = '/';
  }
  return Promise.reject(error);
};

api.interceptors.response.use((response) => response, apiError);

export default api;
