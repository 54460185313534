import { lazy } from "react";

const Login = lazy(() => import("./pages/login"));
const Notfound = lazy(() => import("./pages/notfound"));

const routes = [
  {
    path: "/",
    exact: true,
    auth: false,
    component: Login,
  },
  {
    path: "/login",
    exact: true,
    auth: false,
    component: Login,
  },
  {
    auth: false,
    component: Notfound,
  },
];

export default routes;
