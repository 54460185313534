import Seller from "../../../model/Seller";
import {
  SET_SELLER_LIST,
  SET_ANNOTATIONS,
  SET_DATA_SEARCH,
  SET_SELLER_REPORT,
} from "./actions";

const listSellers = (state, payload) => {
  const { data, type, ...meta } = payload;

  const sellers = data.map((seller) => new Seller(seller));
  return { ...state, [type]: { data: sellers, meta } };
};

const listAnnotations = (state, payload) => {
  const { profileId } = payload;

  return {
    ...state,
    annotations: { ...state.annotations, [profileId]: payload },
  };
};

const listDataSearchResult = (state, payload) => {
  return {
    ...state,
    dataSearch: payload,
  };
};

const listSellerReport = (state, payload) => {
  return {
    ...state,
    report: payload,
  };
};

const initialState = { annotations: {} };

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELLER_LIST:
      return listSellers(state, payload);
    case SET_ANNOTATIONS:
      return listAnnotations(state, payload);
    case SET_DATA_SEARCH:
      return listDataSearchResult(state, payload);
    case SET_SELLER_REPORT:
      return listSellerReport(state, payload);
    default:
      return state;
  }
};

export default reducer;
