export const SET_DEPENDENCIES = 'SET_DEPENDENCIES';
export const SET_SOCKET = 'SET_SOCKET';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_ONLINE_USERS = 'SET_ONLINE_USERS';
export const SET_SUPPORT_LIST = 'SET_SUPPORT_LIST';
export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';
export const UPDATE_MY_CONVERSATION = 'UPDATE_MY_CONVERSATION';
export const SET_ADMIN_CONVERSATIONS = 'SET_ADMIN_CONVERSATIONS';
export const SET_ADMIN_MESSAGES = 'SET_ADMIN_MESSAGES';
export const INVITE_TO_CHAT = 'INVITE_TO_CHAT';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const FAILED_MESSAGES = 'FAILED_MESSAGES';
export const REMOVE_SUPPORT = 'REMOVE_SUPPORT';
export const SET_CHAT_LOGS = 'SET_CHAT_LOGS';
export const DELETE_CHAT_LOG = 'DELETE_CHAT_LOG';
