import { lazy } from "react";

import { NavigationTitles } from "../constants";

const ClosedModule = lazy(() => import("../close.module"));
const profiles = lazy(() => import("../pages/seller-profiles"));
const pending = lazy(() => import("../pages/seller-profiles/pending"));
const live = lazy(() => import("../pages/seller-profiles/live"));
const nonLive = lazy(() => import("../pages/seller-profiles/non-live"));
const profileCollections = lazy(() => import("../pages/profile-collection"));
const createCollection = lazy(() =>
  import("../pages/profile-collection/create")
);
const editCollection = lazy(() => import("../pages/profile-collection/edit"));
const setProfileCollections = lazy(() =>
  import("../pages/profile-collection/set-profiles/")
);
const profileCollectionFAQs = lazy(() =>
  import("../pages/profile-collection/faqs")
);
const profileCollection = lazy(() =>
  import("../pages/profile-collection/collection")
);
const profileReport = lazy(() => import("../pages/seller-profiles/report"));

const title = NavigationTitles.profiles;

const sellerProfileRoutes = [
  {
    path: "/backend/seller-profiles",
    exact: true,
    component: profiles,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/seller-profiles/pending",
    exact: true,
    component: pending,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/seller-profiles/non-live",
    exact: true,
    component: nonLive,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/seller-profiles/live",
    exact: true,
    component: live,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/seller-profiles/report",
    exact: true,
    component: profileReport,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/profile-collection",
    exact: true,
    component: profileCollections,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/profile-collection/create",
    exact: true,
    component: createCollection,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/profile-collection/edit/:id",
    exact: true,
    component: editCollection,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/profile-collection/set-profiles/:id",
    exact: true,
    component: setProfileCollections,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/profile-collection/faqs/:id",
    exact: true,
    component: profileCollectionFAQs,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/profile-collection/:id",
    exact: true,
    component: profileCollection,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Developers",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default sellerProfileRoutes;
