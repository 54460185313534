import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';
import Notify from './notify';
import {
  authLogin,
  authPageLoading,
  authResetPassword,
  authToggleResetPasswordModal,
} from '../modules/auth/store/actions';

const poolData = {
  UserPoolId: 'eu-west-1_ffg2FThwq',
  ClientId: '6cukjqsj91e6gnbtclmvh50rss',
};

const userPool = new CognitoUserPool(poolData);

class Auth {
  constructor() {
    this.poolData = poolData;
    this.userPool = userPool;
  }

  //Checks authentication status
  isAuthenticated = () => {
    const currentUser = this.userPool.getCurrentUser();

    return currentUser != null;
  };

  getTokenFromSession = (session) => {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
    };
  };

  /**
   * @param {String} ["idToken" || "refreshToken" || 'accessToken']
   */

  getUserToken = (tokenType) => {
    const currentUser = this.userPool.getCurrentUser();

    if (currentUser != null) {
      let userToken;

      //Callback for checkTokenExpiration
      const setValidToken = (session) => {
        userToken = this.getTokenFromSession(session)[tokenType];
      };

      currentUser.getSession((err, session) => {
        if (err) {
          currentUser.signOut();
          this.redirectToLogin();
        } else {
          const tokens = this.getTokenFromSession(session);
          this.checkTokenExpiration(tokens, setValidToken);
        }
      });

      return userToken;
    }
    currentUser && currentUser.signOut();
    this.redirectToLogin();
  };

  getDataFromToken = (data) => {
    const idToken = this.getUserToken('idToken');
    try {
      const decoded = jwtDecode(idToken);
      return decoded[data];
    } catch (error) {
      return error;
    }
  };

  getUser = () => {
    const idToken = this.getUserToken('idToken');
    try {
      const decoded = jwtDecode(idToken);
      return decoded;
    } catch (error) {
      return {};
    }
  };

  redirectToLogin = () => {
    window.location.href = '/';
  };

  login = (email, password, callback, dispatch) => {
    const authenticationData = {
      Username: email.toLowerCase().trim(),
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email.toLowerCase().trim(),
      Pool: this.userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const idToken = result.idToken.jwtToken;
        const payload = {
          token: idToken,
          user: this.getUser(),
        };

        dispatch && dispatch(authLogin(payload));
        dispatch && dispatch(authPageLoading(false));
        callback && callback();
      },
      onFailure: (err) => {
        Notify.error(err.message);
        dispatch && dispatch(authPageLoading(false));
      },
      newPasswordRequired: (userAttributes) => {
        dispatch &&
          dispatch(
            authResetPassword({ attributes: userAttributes, user: cognitoUser })
          );
        dispatch && dispatch(authPageLoading(false));
      },
    });
  };

  changePasswordFlow = (password, userData, currentUser, dispatch) => {
    dispatch(authPageLoading(true));

    const cognitoUser = currentUser;
    const userAttributes = { ...userData };
    delete userAttributes.email_verified;
    delete userAttributes.email;
    delete userAttributes.phone_number;
    delete userAttributes.phone_number_verified;
    cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
      onSuccess: function (result) {
        dispatch(authPageLoading(false));
        dispatch(authToggleResetPasswordModal());
        Notify.success(
          'Details Changed Successfully, Please login with NEW PASSWORD'
        );
      },
      onFailure: function (err) {
        dispatch(authPageLoading(false));

        Notify.error(err.message);
      },
    });
  };

  checkTokenExpiration(tokens, setValidSession) {
    const AccessToken = new CognitoAccessToken({
      AccessToken: tokens.accessToken,
    });
    const IdToken = new CognitoIdToken({ IdToken: tokens.idToken });
    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: tokens.refreshToken,
    });

    const sessionData = {
      IdToken,
      AccessToken,
      RefreshToken,
    };

    const cachedSession = new CognitoUserSession(sessionData);

    if (cachedSession.isValid()) {
      setValidSession(cachedSession);
    } else {
      const currentUser = this.userPool.getCurrentUser();
      currentUser.refreshSession(RefreshToken, (err, session) => {
        if (err) {
          currentUser.signOut();
          this.redirectToLogin();
        } else {
          setValidSession(session);
        }
      });
    }
  }

  refreshSession(callback) {
    const refreshToken = this.getUserToken('refreshToken');
    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    const currentUser = this.userPool.getCurrentUser();
    currentUser.refreshSession(token, function (err, session) {
      if (!err) callback(session);
    });
  }

  logout = () => {
    //Signout of cognito
    const currentUser = this.userPool.getCurrentUser();
    currentUser.getSession((err) => {
      if (err) {
        currentUser.signOut();
      }
    });

    currentUser.globalSignOut({
      onSuccess: (result) => {
        return result;
      },
      onFailure: (err) => {
        return err;
      },
    });
  };

  tokenRefreshInterval = () => {
    return setInterval(() => this.refreshSession(() => 'refresh'), 3000000);
  };
}

export default Auth;
