import buyerRoutes from "./buyer";
import sellerRoutes from "./sellers";
import otherRoutes from "../routes";
import developerRoutes from "./developers";
import transactionRoutes from "./transactions";
import discountRoutes from "./discount";
import paymentRoutes from "./payments";
import marketplaceRoutes from "./marketplace";
import userRoutes from "./users";
import workstoreRoutes from "./workstore";
import sellerProfileRoutes from "./seller-profiles";
import financeRoutes from "./finance";
import employRoutes from "./employ";
import messagingRoutes from "./messaging";

const closedRoutes = [
  ...buyerRoutes,
  ...sellerRoutes,
  ...sellerProfileRoutes,
  ...workstoreRoutes,
  ...employRoutes,
  ...marketplaceRoutes,
  ...otherRoutes,
  ...messagingRoutes,
  ...transactionRoutes,
  ...discountRoutes,
  ...paymentRoutes,
  ...userRoutes,
  ...financeRoutes,
  ...developerRoutes,
];

export default closedRoutes;
