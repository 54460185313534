import {
  SET_DEPENDENCIES,
  SET_SOCKET,
  SET_CONVERSATIONS,
  SET_ONLINE_USERS,
  SET_SUPPORT_LIST,
  SET_MESSAGES,
  UPDATE_CONVERSATIONS,
  SET_ADMIN_CONVERSATIONS,
  SET_ADMIN_MESSAGES,
  INVITE_TO_CHAT,
  UPDATE_MESSAGES,
  FAILED_MESSAGES,
  SET_CHAT_LOGS,
  REMOVE_SUPPORT,
  UPDATE_MY_CONVERSATION,
  DELETE_CHAT_LOG,
} from './action-types';

/**Sellers */
export function setDependencies(payload) {
  return {
    type: SET_DEPENDENCIES,
    payload,
  };
}

export function setSocket(payload) {
  return {
    type: SET_SOCKET,
    payload,
  };
}

export function setConversations(payload) {
  return {
    type: SET_CONVERSATIONS,
    payload,
  };
}

export function setAdminConversations(payload) {
  return {
    type: SET_ADMIN_CONVERSATIONS,
    payload,
  };
}

export function setOnlineUsers(payload) {
  return {
    type: SET_ONLINE_USERS,
    payload,
  };
}

export function setSupportList(payload) {
  return {
    type: SET_SUPPORT_LIST,
    payload,
  };
}

export function setMessageList(payload) {
  return {
    type: SET_MESSAGES,
    payload,
  };
}

export function updateConversations(payload) {
  return {
    type: UPDATE_CONVERSATIONS,
    payload,
  };
}

export function setAdminMessages(payload) {
  return {
    type: SET_ADMIN_MESSAGES,
    payload,
  };
}

export function inviteUserToChat(payload) {
  return {
    type: INVITE_TO_CHAT,
    payload,
  };
}

export function updateMyConversation(payload) {
  return {
    type: UPDATE_MY_CONVERSATION,
    payload,
  };
}

export function updateMessages(payload) {
  return {
    type: UPDATE_MESSAGES,
    payload,
  };
}

export function failedMessage(payload) {
  return {
    type: FAILED_MESSAGES,
    payload,
  };
}

export function removeSupport(payload) {
  return {
    type: REMOVE_SUPPORT,
    payload,
  };
}

export function setChatLogs(payload) {
  return {
    type: SET_CHAT_LOGS,
    payload,
  };
}

export function deleteChatLog(payload) {
  return {
    type: DELETE_CHAT_LOG,
    payload,
  };
}
