import moment from 'moment';
import Model from '../../../utils/Model';

class StorePost extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  getStatus(status) {
    switch (status) {
      case 0:
        return { name: 'Pending', color: 'red' };
      case 1:
        return { name: 'Approved', color: 'green' };
      case 2:
        return { name: 'Declined', color: 'red' };
      default:
        return '-';
    }
  }

  initialize(props) {
    super.initialize(props);
    this.status = this.getStatus(props.approved);
    this.expired = moment().isAfter(moment(props.workStartDate), 'day');
    this.responseRatio = `${props.noOfReplies}/${props.maxNoOfExpectedResponse}`;
    this.postDate = moment(props.postDate);
    this.start = moment(props.workStartDate);
    this.id = props.messageId;
    this.rawText = props.customRequest.replace(/<[^>]*>/g, '');
    this.country = props.countryId || props.buyer.countryId || 402;
    this.duration = props.jobDuration
      ? `${props.jobDuration} ${props.jobDurationUnit || 'days'}`
      : 'Not specified';
  }
}

export default StorePost;
