import { lazy } from "react";
import { NavigationTitles } from "../constants";

const ClosedModule = lazy(() => import("../close.module"));
const buyers = lazy(() => import("../pages/buyers"));
const buyer = lazy(() => import("../pages/buyers/buyer"));
const reviews = lazy(() => import("../pages/buyers/reviews"));
const buyerReviews = lazy(() => import("../pages/buyers/reviews/pending"));
const buyerRecent = lazy(() => import("../pages/buyers/recent-visit"));

const title = NavigationTitles.buyers;

const buyerRoutes = [
  {
    path: "/backend/buyers",
    exact: true,
    component: buyers,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/buyers/:id",
    exact: true,
    component: buyer,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/buyers-reviews",
    exact: true,
    component: reviews,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/buyers-reviews/pending",
    exact: true,
    component: buyerReviews,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/buyer-recent",
    exact: true,
    component: buyerRecent,
    permissions: [],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default buyerRoutes;
