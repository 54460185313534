import {
  SET_BANKS,
  SET_FAQS,
  SET_JOB_TITLES,
  SET_AFFILIATE,
  SET_AFFILIATES_LIST,
} from './actions';

const initialState = {
  faqs: {},
  jobTitles: null,
  affiliates: {},
};

const listBanks = (state, payload) => ({ ...state, banks: payload });

const setFaqs = (state, payload) => ({
  ...state,
  faqs: { ...state.faqs, [payload.slug]: payload },
});

const listJobTitles = (state, payload) => ({ ...state, jobTitles: payload });

const setAffiliate = (state, payload) => ({
  ...state,
  affiliates: { ...state.affiliates, [payload.id]: payload },
});

const setAffiliateList = (state, payload) => ({
  ...state,
  affiliatesList: payload,
});

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BANKS:
      return listBanks(state, payload);
    case SET_FAQS:
      return setFaqs(state, payload);
    case SET_JOB_TITLES:
      return listJobTitles(state, payload);
    case SET_AFFILIATE:
      return setAffiliate(state, payload);
    case SET_AFFILIATES_LIST:
      return setAffiliateList(state, payload);
    default:
      return state;
  }
};
