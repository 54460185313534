import { ADD_TO_JOB_GROUP, LIST_JOBS } from './actions';

const initialState = {
  jobs: {},
  jobGroup: {},
};

const listJobs = (state, payload) => {
  return { ...state, jobs: { ...state.jobs, [payload.type]: payload } };
};

const addToJobGroup = (state, payload) => {
  return { ...state, jobGroup: { ...state.jobGroup, [payload.id]: payload } };
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_JOBS:
      return listJobs(state, payload);
    case ADD_TO_JOB_GROUP:
      return addToJobGroup(state, payload);
    default:
      return state;
  }
};

export default reducer;
