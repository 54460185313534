import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Components
import Header from '../../common/header/Header';
import { navItems } from './constants';
import { Box } from '@chakra-ui/layout';
import FullTimeSidebar from '../../common/sidebar/FullTimeSidebar';

function FullTimeModule({
  title,
  firstName,
  lastName,
  dispatch,
  children,
  hideHeader,
}) {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSideBar = useCallback(() => setShowSidebar((prev) => !prev), []);
  return (
    <div className="page">
      <FullTimeSidebar
        active={title}
        showSidebar={showSidebar}
        toggleSideBar={toggleSideBar}
      />
      <div className="page-main smoke-white p-rel">
        {!hideHeader && (
          <Header
            title={title}
            firstName={firstName}
            lastName={lastName}
            dispatch={dispatch}
            navItems={navItems}
            toggleSideBar={toggleSideBar}
          />
        )}
        <Box
          as="main"
          mt={hideHeader ? '0' : '80px'}
          p={hideHeader ? 0 : '2rem'}
          pos="relative"
        >
          {children}
        </Box>
      </div>
    </div>
  );
}

FullTimeModule.propTypes = {
  title: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  title: ownProps.title,
  firstName: state.auth.user && state.auth.user.givenName,
  lastName: state.auth.user && state.auth.user.familyName,
});

export default connect(mapStateToProps)(FullTimeModule);
