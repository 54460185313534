import {
  GET_ALL_ADMIN_CHAT,
  GET_ADIM_CHAT,
  CREATE_NEW_ADMIN_CONVERSATION,
  GET_ADMIN_CHAT_MESSAGES,
  SEND_ADMIN_CHAT_MESSAGE,
  ASSIGN_TO_CHAT,
  UNASSIGN_TO_CHAT,
  UPDATE_ADMIN_LAST_SEEN,
} from "../../../../settings/chat/actions";
import Chat from "./Chat";

class AdminChat extends Chat {
  getAllAdminChat = () => {
    this.socket.emit(GET_ALL_ADMIN_CHAT);
  };

  getAdminChat = () => {
    this.socket.emit(GET_ADIM_CHAT);
  };

  createAdminConversation = async (payload) => {
    await this.socket.emit(CREATE_NEW_ADMIN_CONVERSATION, payload, () => {
      this.getAllAdminChat();
    });
  };

  getAdminChatMessages = (conversationId) => {
    this.socket.emit(GET_ADMIN_CHAT_MESSAGES, { conversationId });
  };

  sendAdminMessage = (payload, callback) => {
    this.socket.emit(SEND_ADMIN_CHAT_MESSAGE, payload, callback);
  };

  assignToChat = (payload) => {
    this.socket.emit(ASSIGN_TO_CHAT, payload);
  };

  unassignToChat = (payload) => {
    this.socket.emit(UNASSIGN_TO_CHAT, payload);
  };

  updateAdminLastSeen = (conversationId) => {
    this.socket.emit(UPDATE_ADMIN_LAST_SEEN, {
      conversationId,
    });
  };
}

export default AdminChat;
