import React from "react";
import { Redirect, Route } from "react-router";

function PublicRoute({ component: Component, ...rest }) {
  return rest.redirect ? (
    <Redirect to={rest.to} from={rest.path} />
  ) : (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );
}

export default PublicRoute;
