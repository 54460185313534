import React, { useMemo, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import shortid from 'shortid';

//import components
import routes from './routes';
import PrivateRoute from './Private';
import PublicRoute from './Public';
import Layout from '../layouts';
import { PageLoader } from '../common/Loaders';

const Routes = () => {
  //Memoize the routes mapping to limit rerenders
  const renderRoutes = useMemo(
    () =>
      routes.map((route) => {
        if (route.auth) {
          return <PrivateRoute key={shortid.generate()} {...route} />;
        }
        return <PublicRoute key={shortid.generate()} {...route} />;
      }),
    []
  );

  const fallbackMarkup = <PageLoader />;

  return (
    <Router>
      <Suspense fallback={fallbackMarkup}>
        <Layout>
          <Switch>{renderRoutes}</Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export default Routes;
