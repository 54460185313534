import { lazy } from 'react';
import { NavigationTitles } from '../constants';

const ClosedModule = lazy(() => import('../close.module'));
const chatLogs = lazy(() => import('../pages/developers/chat/logs'));

const title = NavigationTitles.developers;

export default [
  {
    path: '/backend/developers/chat/logs',
    exact: true,
    component: chatLogs,
    permissions: ['SuperAdmin', 'Developers'],
    title: title,
    module: ClosedModule,
    auth: true,
  },
];
