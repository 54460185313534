export const LIST_JOBS = 'LIST_JOBS';
export const ADD_TO_JOB_GROUP = 'ADD_TO_JOB_GROUP';

export function listJobs(payload) {
  return {
    type: LIST_JOBS,
    payload,
  };
}

export function addToJobGroup(payload) {
  return {
    type: ADD_TO_JOB_GROUP,
    payload,
  };
}
