import Model from '../../../utils/Model';
import Transformer from '../../../utils/transformer';

class Seller extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  initialize(props) {
    super.initialize(props);

    this.email = props.email || '';
    this.items = props.items || '';
    this.name = `${props.firstName} ${props.lastName}` || '';
    this.username = props.username || '';
    this.note = props.adminNote || '';
    this.miniNote = Transformer.truncate(props.adminNote, 50);
    this.buyerId = props.buyerId || 0;
    this.level = {
      tag: props.levelTag || 'L1',
      score: props.levelScore || '0.00',
    };
    this.phone = props.contactInfo.telephoneNo || '-';
    this.url = `/backend/sellers/${this.id}`;
    this.type = props.registrationType.name;
    this.publicStatus = props.publicProfileStatus ? 'Public' : 'Non-Public';
    this.summaryColor = props.workInfoAdmin.summaryApproved;
    this.summary = {
      value: props.workInfoAdmin.summary || '',
      status: props.workInfoAdmin.summaryApproved ? 'Approved' : 'Pending',
      color: props.workInfoAdmin.summaryApproved ? 'green' : 'red',
      approved: props.workInfoAdmin.summaryApproved,
    };
    this.paymentStatus = {
      status: props.paymentInfoAdmin.approved ? 'Approved' : 'Pending',
      color: props.paymentInfoAdmin.approved ? 'green' : 'red',
    };
  }
}

export default Seller;
