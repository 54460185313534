import { lazy } from 'react';
import { UserRoles } from '../../auth/constants';
import { NavigationTitles } from '../constants';

const ClosedModule = lazy(() => import('../close.module'));
const ongoingJobs = lazy(() => import('../pages/Transactions/ongoing'));
const submittedJobs = lazy(() =>
  import('../pages/Transactions/ongoing/submitted')
);
const revisionJobs = lazy(() =>
  import('../pages/Transactions/ongoing/revision')
);
const acceptedJobs = lazy(() =>
  import('../pages/Transactions/ongoing/accepted')
);
const resolutionJobs = lazy(() =>
  import('../pages/Transactions/ongoing/resolution')
);
const escalatedJobs = lazy(() =>
  import('../pages/Transactions/ongoing/escalated')
);

const work = lazy(() => import('../pages/Transactions/ongoing/work'));

const pendingOrders = lazy(() =>
  import('../pages/Transactions/orders/pending')
);

const title = NavigationTitles.transactions;

const transactionsRoutes = [
  {
    path: '/backend/work/ongoing',
    exact: true,
    component: ongoingJobs,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      UserRoles.UTILITY,
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/work/ongoing/submitted',
    exact: true,
    component: submittedJobs,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/work/ongoing/accepted',
    exact: true,
    component: acceptedJobs,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/work/ongoing/revision',
    exact: true,
    component: revisionJobs,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/work/ongoing/resolution',
    exact: true,
    component: resolutionJobs,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      UserRoles.SUPER_ADMIN,
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/work/ongoing/escalated',
    exact: true,
    component: escalatedJobs,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      UserRoles.TALENT_SPECIALIST,
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/work/ongoing/:id',
    exact: true,
    component: work,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      UserRoles.UTILITY,
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/orders/pending',
    exact: true,
    component: pendingOrders,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default transactionsRoutes;
