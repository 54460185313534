import { lazy } from 'react';
import { NavigationTitles } from '../constants';

const ClosedModule = lazy(() => import('../close.module'));
const report = lazy(() => import('../pages/finance/report'));
const balances = lazy(() => import('../pages/finance/balances'));
const volumes = lazy(() => import('../pages/finance/volume'));
const wallets = lazy(() => import('../pages/finance/wallet'));

const title = NavigationTitles.finances;

const financeRoutes = [
  {
    path: '/backend/finance/report',
    exact: true,
    component: report,
    permissions: ['Admin', 'SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/finance/balances',
    exact: true,
    component: balances,
    permissions: ['Admin', 'SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/finance/volume-report',
    exact: true,
    component: volumes,
    permissions: ['Admin', 'SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/finance/wallets',
    exact: true,
    component: wallets,
    permissions: ['Admin', 'SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default financeRoutes;
