export const GET_CONVERSATIONS = "getAllConversations";
export const GET_ALL_SUPPORTS = "getAllSupports";
export const GET_ADMIN_MESSAGES = "getMessageByAdmin";
export const GET_MY_CONVERSATIONS = "getConversations";
export const GET_MESSAGES = "getMessages";
export const CREATE_GROUP_CONVERSATION = "createGroupConversation";
export const ADD_PARTICIPANT = "addParticipant";
export const SEND_MESSAGE = "sendMessage";
export const GET_ERROR_LOGS = "getLogs";
export const DELETE_LOG = "deleteLog";

//ADMIN CHAT
export const GET_ALL_ADMIN_CHAT = "getTeraConversations";
export const GET_ADIM_CHAT = "getTeraConversation";
export const SEND_ADMIN_CHAT_MESSAGE = "sendChatMessage";
export const CREATE_NEW_ADMIN_CONVERSATION = "newTeraConversation";
export const GET_ADMIN_CHAT_MESSAGES = "getChatMessages";
export const ASSIGN_TO_CHAT = "assignAdminTeraConversation";
export const UNASSIGN_TO_CHAT = "unAssignAdminTeraConversation";
export const UPDATE_ADMIN_LAST_SEEN = "adminChatUpdateLastSeen";
