import Model from '../../../utils/Model';

class Buyer extends Model {
  constructor(params) {
    super(params);

    this.initialize(params);
  }

  initialize(params) {
    super.initialize(params);

    this.name = `${params.firstName} ${params.lastName}` || '';
    this.phone = params.telephoneNo;
    this.url = `/backend/buyers/${params.id}`;
    this.avatar = params.logoOrProfilePic;
  }
}

export default Buyer;
