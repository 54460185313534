import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  AUTH_USER,
  AUTH_PAGELOADING,
  AUTH_CONTENTLOADING,
  AUTH_TOGGLESIDEBAR,
  AUTH_RESET_PASSWORD,
  AUTH_TOGGLE_RESET_PASSWORD_MODAL,
} from './action-types';

export function authCheck(payload) {
  return {
    type: AUTH_CHECK,
    payload,
  };
}

export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}

export function authRefreshToken(payload) {
  return {
    type: AUTH_REFRESH_TOKEN,
    payload,
  };
}

export function authResetPassword(payload) {
  return {
    type: AUTH_RESET_PASSWORD,
    payload,
  };
}

export function authToggleResetPasswordModal() {
  return {
    type: AUTH_TOGGLE_RESET_PASSWORD_MODAL,
  };
}

export function authToggleSidebar() {
  return {
    type: AUTH_TOGGLESIDEBAR,
  };
}

export function authUser(payload) {
  return {
    type: AUTH_USER,
    payload,
  };
}

export function authPageLoading(payload) {
  return {
    type: AUTH_PAGELOADING,
    payload,
  };
}

export function authContentLoading(payload) {
  return {
    type: AUTH_CONTENTLOADING,
    payload,
  };
}
