import {
  SET_BALANCES,
  SET_VOLUME_REPORT,
  SET_WALLETS,
  SET_ESCROWED,
} from './actions';

const setBalances = (state, payload) => {
  return { ...state, balances: payload };
};

const setWallets = (state, payload) => {
  return {
    ...state,
    wallets: { ...state.wallets, [payload.type]: payload.wallets },
  };
};

const setEscrowed = (state, payload) => ({
  ...state,
  escrowed: { ...state.escrowed, [payload.type]: payload.escrowed },
});

const setVolumeReport = (state, payload) => ({ ...state, volume: payload });

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_BALANCES:
      return setBalances(state, payload);
    case SET_VOLUME_REPORT:
      return setVolumeReport(state, payload);
    case SET_WALLETS:
      return setWallets(state, payload);
    case SET_ESCROWED:
      return setEscrowed(state, payload);
    default:
      return state;
  }
};

export default reducer;
