export const SET_CASHOUT_CHARGES = 'SET_CASHOUT_CHARGES';
export const SET_CASHOUT_CHARGE = 'SET_CASHOUT_CHARGE';
export const SET_TOPUPS = 'SET_TOPUPS';

export const listCashoutCharges = (payload) => ({
  type: SET_CASHOUT_CHARGES,
  payload,
});

export const setCashoutCharge = (payload) => ({
  type: SET_CASHOUT_CHARGE,
  payload,
});

export const setTopups = (payload) => ({
  type: SET_TOPUPS,
  payload,
});
