import {
  SELLERS_LIST,
  GET_SELLER,
  LIST_GALLERY,
  DELETE_GALLERY,
  LIST_WORKSTORE,
  GET_POST,
  LIST_PROMOS,
  GET_PROMO,
  SET_PROMO_PROFILE,
  LIST_BUYERS,
  SET_BUYER,
  SET_ONGOING_JOB,
  LIST_ONGOING_JOBS,
  LIST_GIFT_CARDS,
  SET_GIFT_CARD,
  SET_SELLER_REVIEWS,
  SET_BUYER_REVIEWS,
  GET_CURRENCY,
  GET_CURRENCY_RATES,
  LIST_NET_CONVERSIONS,
  UPDATE_CURRENCY_RATE,
  LIST_PAYMENTS,
  SET_PAYMENT,
  LIST_CASHOUTS,
  SET_TRANSACTION,
  LIST_DISCOUNT_CODES,
  GET_DISCOUNT_CODE,
  LIST_USERS,
  LIST_KEYWORDS,
  SET_BUYER_RECENTLY_VISITED,
} from "./action-types";

/**Sellers */
export function listSellers(payload) {
  return {
    type: SELLERS_LIST,
    payload,
  };
}

export function getSeller(payload) {
  return {
    type: GET_SELLER,
    payload,
  };
}

export function listGallery(payload) {
  return {
    type: LIST_GALLERY,
    payload,
  };
}

export function deleteGallery(payload) {
  return {
    type: DELETE_GALLERY,
    payload,
  };
}

export function setSellersReviews(payload) {
  return {
    type: SET_SELLER_REVIEWS,
    payload,
  };
}

//Workstore

export function listWorkStore(payload) {
  return {
    type: LIST_WORKSTORE,
    payload,
  };
}

export function getWorkstorePost(payload) {
  return {
    type: GET_POST,
    payload,
  };
}

export function listPromos(payload) {
  return {
    type: LIST_PROMOS,
    payload,
  };
}

export function getPromo(payload) {
  return {
    type: GET_PROMO,
    payload,
  };
}

export function getPromoProfile(payload) {
  return {
    type: SET_PROMO_PROFILE,
    payload,
  };
}

//Buyer Actions
export function listBuyers(payload) {
  return {
    type: LIST_BUYERS,
    payload,
  };
}

export function setBuyer(payload) {
  return {
    type: SET_BUYER,
    payload,
  };
}

export function setBuyerReviews(payload) {
  return {
    type: SET_BUYER_REVIEWS,
    payload,
  };
}

export function setBuyersRecentlyVisited(payload) {
  return {
    type: SET_BUYER_RECENTLY_VISITED,
    payload,
  };
}

//Transactions
export function listOngoingJobs(payload) {
  return {
    type: LIST_ONGOING_JOBS,
    payload,
  };
}

export function setOngoingJob(payload) {
  return {
    type: SET_ONGOING_JOB,
    payload,
  };
}

//Gift Cards
export function listGiftCards(payload) {
  return {
    type: LIST_GIFT_CARDS,
    payload,
  };
}

export function setGiftCard(payload) {
  return {
    type: SET_GIFT_CARD,
    payload,
  };
}

//CURRENCY

export function getCurrency(payload) {
  return {
    type: GET_CURRENCY,
    payload,
  };
}

export function getCurrencyRates(payload) {
  return {
    type: GET_CURRENCY_RATES,
    payload,
  };
}

export function listNetConversions(payload) {
  return {
    type: LIST_NET_CONVERSIONS,
    payload,
  };
}

export function updateCurrencyRates(payload) {
  return {
    type: UPDATE_CURRENCY_RATE,
    payload,
  };
}

export function listPayments(payload) {
  return {
    type: LIST_PAYMENTS,
    payload,
  };
}

export function setPayment(payload) {
  return {
    type: SET_PAYMENT,
    payload,
  };
}

export function listCashouts(payload) {
  return {
    type: LIST_CASHOUTS,
    payload,
  };
}

export function setTransaction(payload) {
  return {
    type: SET_TRANSACTION,
    payload,
  };
}

//Discount codes

export function listDiscountCodes(payload) {
  return {
    type: LIST_DISCOUNT_CODES,
    payload,
  };
}

export function getDiscountCode(payload) {
  return {
    type: GET_DISCOUNT_CODE,
    payload,
  };
}

export function listUsers(payload) {
  return { type: LIST_USERS, payload };
}

export function listKeywords(payload) {
  return { type: LIST_KEYWORDS, payload };
}
