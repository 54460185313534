import { useCallback, useEffect, useRef } from 'react';
import socketIOClient from 'socket.io-client';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import * as actions from '../modules/settings/store/actions';
import Socket from '../modules/settings/chat/socket.services';
import {
  CONVERSATION_UPDATE,
  CONV_LIST,
  MSG_LIST,
  ONLINE_USERS,
  SUPPORT_LIST,
  ALL_CONV_LIST,
  ADMIN_MSG_LIST,
  FAILED_MSG,
  INCOMING,
  LOG_LIST,
  REMOVE_SUPPORT,
  CREATE_GROUP,
  CONV_UPDATE,
  TERA_CONV_UPDATE,
  TERA_CONV_LIST,
  TERA_MESSAGE_LIST,
  TERA_INCOMING,
} from '../modules/settings/chat/listeners';
import * as adminChatActions from '../modules/close/pages/Messaging/redux/action';
import { useToast } from '@chakra-ui/toast';
import { trimText } from '../utils/converters';
import { Link } from '@chakra-ui/layout';
import { useAdminChat } from '../hooks/useAdminChat';
import moment from 'moment';

const useAdminNotification = () => {
  const { pathname } = useLocation();
  const adminUser = useSelector((state) => state.auth.user);
  const toast = useToast();
  const username = pathname.split('/')[4] || '';

  const sendNotification = useCallback(
    (msg) => {
      const toastId = msg.message;
      if (
        username?.toLowerCase() !== msg.senderName?.toLowerCase() &&
        adminUser.id === msg.receiverId &&
        !toast.isActive(toastId)
      ) {
        toast({
          title: `New message from ${msg.senderName}`,
          id: msg.message,
          position: 'top-right',
          duration: 6000,
          description: (
            <Link href={`/backend/messages/admin-chat/${msg.senderName}`}>
              {trimText(msg.message, 40)}
            </Link>
          ),
          variant: 'subtle',
          status: 'success',
        });
      }
    },
    [adminUser.id, toast, username]
  );

  return {
    sendNotification,
  };
};

function SocketHandler({ chatName, isAuthenticated, dispatch, permission }) {
  const history = useHistory();
  const { sendNotification } = useAdminNotification();
  const { setNewMessageIndicator } = useAdminChat();
  const setNewMessageIndicatorRef = useRef();
  setNewMessageIndicatorRef.current = setNewMessageIndicator;
  const socketInitiated = useRef(false);

  useEffect(() => {
    if (isAuthenticated && chatName) {
      if (!socketInitiated.current) {
        const io = socketIOClient(
          `wss://chat.terawork.com:8443?username=${chatName}&userType=admin&permission=sa` //TEmporarily give access to all admin to see all chats
        );

        const socket = new Socket(io);

        socket.on(ALL_CONV_LIST, (data) => {
          dispatch(actions.setConversations(data));
        });

        socket.on(ONLINE_USERS, (data) => {
          dispatch(actions.setOnlineUsers(data.users));
        });

        socket.on(ADMIN_MSG_LIST, (data) => {
          dispatch(actions.setAdminMessages(data));
        });

        socket.on(MSG_LIST, (data) => {
          dispatch(actions.setMessageList(data));
        });

        socket.on(SUPPORT_LIST, (data) => {
          dispatch(actions.setSupportList(data.supports));
        });

        socket.on(CONVERSATION_UPDATE, (data) => {
          dispatch(actions.updateConversations(data));
        });

        socket.on(CONV_LIST, (data) => {
          dispatch(actions.setAdminConversations(data));
        });

        socket.on(FAILED_MSG, (data) => {
          dispatch(actions.failedMessage(data));
        });

        socket.on(INCOMING, (data) => {
          dispatch(actions.updateMessages(data));
        });

        socket.on(REMOVE_SUPPORT, (data) => {
          dispatch(actions.removeSupport(data));
        });

        socket.on(CREATE_GROUP, (data) => {
          history.push(`/backend/messages/chat/${data.id}`);
        });

        socket.on(CONV_UPDATE, (data) => {
          dispatch(actions.updateMyConversation(data));
        });

        socket.on(LOG_LIST, ({ logs }) => {
          dispatch(actions.setChatLogs(logs));
        });

        socket.on(TERA_CONV_LIST, (data) => {
          const convs = data.convs.map((conv) => {
            const newObj = { ...conv, newMessage: false };
            if (!newObj.timestamp) {
              newObj.timestamp = 0;
            }
            const timestamp = parseInt(conv.timestamp, 10);

            if (
              conv.lastseen &&
              moment(new Date(conv.lastseen)).isBefore(
                moment(new Date(newObj.timestamp)),
                'seconds'
              )
            ) {
              newObj.newMessage = true;
            }

            if (conv.username === 'Abayomi79') {
              console.log(newObj.newMessage, 'New MSG');
            }

            return {
              ...newObj,
              lastMessageTime: conv.lastMessageTime || timestamp,
              timestamp,
            };
          });
          dispatch(adminChatActions.listAdminChats({ convs }));
        });

        socket.on(TERA_MESSAGE_LIST, (data) => {
          dispatch(adminChatActions.listChatMessages(data));
        });

        socket.on(TERA_CONV_UPDATE, (data) => {
          dispatch(adminChatActions.updateAdminConversation(data));
        });

        socket.on(TERA_INCOMING, (data) => {
          dispatch(adminChatActions.addMessage(data));
          sendNotification(data);
          setNewMessageIndicatorRef.current(data);
        });

        dispatch(actions.setSocket(io));
        socketInitiated.current = true;
      }
    }
  }, [chatName, isAuthenticated, dispatch, history, sendNotification]);

  return null;
}

const mapStateToProps = (state = {}) => ({
  chatName: state.auth.user && state.auth.user.chatName,
  permission:
    state.auth.user?.cognitoGroups &&
    (state.auth.user?.cognitoGroups[1] || state.auth.user?.cognitoGroups[0]),
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(SocketHandler);
