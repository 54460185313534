import { extendTheme } from '@chakra-ui/react';

const brand = {
  primary: '#4b7466',
  500: '#4b7466',
  600: '#304D43',
};

const text = {
  link: '#37A000',
  dark: '#4b7466',
  brand: '#4b7466',
};

const secondary = {
  200: '#83BC2320',
  500: '#83BC23',
  600: '#37A000',
};

export const iconSizes = {
  xl: 12,
  sm: 16,
  md: 24,
  lg: 40,
};

const stylesOverrides = {
  colors: {
    brand,
    text,
    secondary,
    borderColor: '#ddd',
  },
  sizes: {
    icon: { ...iconSizes },
  },
  components: {
    Button: {
      defaultProps: {
        variant: 'solid',
      },
    },
  },
};

export const theme = extendTheme(stylesOverrides);
