import Model from '../../../utils/Model';

class Promo extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  getPromoStatus(status) {
    switch (status) {
      case 10:
        return 'Live';
      case 1:
        return 'Not Launched';
      case 0:
        return 'Ended';
      default:
        return 'Unknown';
    }
  }

  initialize(props) {
    super.initialize(props);

    this.statusText = this.getPromoStatus(props.status);
  }
}

export default Promo;
