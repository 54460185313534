import moment from 'moment';

import Model from '../../../utils/Model';
import { statusColor } from '../../auth/constants';

class DiscountCode extends Model {
  constructor(params) {
    super(params);
    this.initialize(params);
    this.DISCOUNT_TYPE_FLAT = 'Flat';
    this.DISCOUNT_TYPE_PERCENTAGE = 'Percentage';
    this.STATUS_ACTIVE = 'ACTIVE';
    this.STATUS_PENDING = 'PENDING';
    this.STATUS_STOPPED = 'STOPPED';
  }

  returnValue(params, symbol) {
    if (params.discountType === this.DISCOUNT_TYPE_FLAT) {
      return `${symbol} ${(+params.discountValue).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }

    return `${params.discountValue}%`;
  }

  initialize(params) {
    super.initialize(params);
    this.statusTextColor = statusColor[params.status.toLowerCase()];
    this.expiryDate = moment(params.expiryDate);
    this.startDate = moment(params.startDate);
    this.approveDate = params.approvedDate
      ? moment(params.approvedDate).format('ll')
      : 'Not Approved';
    this.showValue = (symbol) => this.returnValue(params, symbol);
    this.singleUser = params.allowSingleUser ? 'Yes' : 'No';
  }
}

export default DiscountCode;
