import { GET_TALENT, GET_TALENT_SEARCH, GET_TALENTS } from './actions';

const initialState = {
  talents: null,
  talentSearch: null,
  talent: {},
};

const listTalents = (state, payload = {}) => {
  return { ...state, talents: payload };
};
const listTalentSearch = (state, payload = {}) => {
  return { ...state, talentSearch: payload };
};

const getTalent = (state, payload = {}) => {
  return { ...state, talent: { ...state.talent, [payload.id]: payload } };
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TALENTS:
      return listTalents(state, payload);
    case GET_TALENT_SEARCH:
      return listTalentSearch(state, payload);
    case GET_TALENT:
      return getTalent(state, payload);
    default:
      return state;
  }
};
