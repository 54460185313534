export const ADMIN_MSG_LIST = "admin-msg-list";
export const MSG_LIST = "msg-list";
export const ALL_CONV_LIST = "all-conv-list";
export const SUPPORT_LIST = "support-list";
export const ONLINE_USERS = "online-users";
export const CONVERSATION_UPDATE = "conversation-update";
export const CONV_LIST = "conv-list";
export const FAILED_MSG = "failed-msg";
export const INCOMING = "incoming";
export const LOG_LIST = "log-list";
export const REMOVE_SUPPORT = "remove-support";
export const CREATE_GROUP = "create-group";
export const CONV_UPDATE = "conv-update";

//Admin Chat

export const TERA_CONV_UPDATE = "tera-conv-update";
export const TERA_CONV_LIST = "tera-conv-list";
export const TERA_MESSAGE_LIST = "tera-msg-list";
export const TERA_INCOMING = "tera-incoming";
