import { lazy } from 'react';
import { NavigationTitles } from '../constants';
import { UserRoles } from '../../auth/constants';

const ClosedModule = lazy(() => import('../close.module'));
const services = lazy(() => import('../pages/services'));
const servicesWaitingPeriod = lazy(() =>
  import('../pages/services/waiting-period')
);
const createService = lazy(() => import('../pages/services/create'));
const showService = lazy(() => import('../pages/services/show'));

const sellerTags = lazy(() => import('../pages/tags/seller'));
const createSellerTags = lazy(() => import('../pages/tags/seller/create'));

const profileTags = lazy(() => import('../pages/tags/profile'));
const createProfileTags = lazy(() => import('../pages/tags/profile/create'));

const categories = lazy(() => import('../pages/categories'));
const createCategory = lazy(() => import('../pages/categories/create'));
const category = lazy(() => import('../pages/categories/show'));
const searchKeywords = lazy(() => import('../pages/keywords'));
const banks = lazy(() => import('../pages/marketplace/banks'));
const jobTitles = lazy(() => import('../pages/marketplace/job-titles'));

//Affiliates
const affiliates = lazy(() => import('../pages/marketplace/affiliates'));
const createAffiliate = lazy(() =>
  import('../pages/marketplace/affiliates/create')
);
const affiliate = lazy(() => import('../pages/marketplace/affiliates/[id]'));
const commissionMatrix = lazy(() => import('../pages/marketplace/commission'));

const title = NavigationTitles.marketplace;

const routes = [
  {
    path: '/backend/services',
    exact: true,
    component: services,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/services/waiting-periods',
    exact: true,
    component: servicesWaitingPeriod,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/services/create',
    exact: true,
    component: createService,
    permissions: ['SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/services/:id',
    exact: true,
    component: showService,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/categories',
    exact: true,
    component: categories,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/categories/create',
    exact: true,
    component: createCategory,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/categories/:id',
    exact: true,
    component: category,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/search-keywords',
    exact: true,
    component: searchKeywords,
    permissions: ['Admin', 'Marketing', 'SuperAdmin', 'Developers'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/tags/seller',
    exact: true,
    component: sellerTags,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/tags/seller/create',
    exact: true,
    component: createSellerTags,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/tags/profile',
    exact: true,
    component: profileTags,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/tags/profile/create',
    exact: true,
    component: createProfileTags,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/banks',
    exact: true,
    component: banks,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/job-titles',
    exact: true,
    component: jobTitles,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },

  {
    path: '/backend/affiliates',
    exact: true,
    component: affiliates,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/affiliates/create',
    exact: true,
    component: createAffiliate,
    // permissions: [UserRoles.ADMIN, UserRoles.DEVELOPERS, UserRoles.SUPER_ADMIN],
    permissions: [],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/affiliates/:id',
    exact: true,
    component: affiliate,
    // permissions: [UserRoles.ADMIN, UserRoles.DEVELOPERS, UserRoles.SUPER_ADMIN],
    permissions: [],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/commission',
    exact: true,
    component: commissionMatrix,
    permissions: [UserRoles.ADMIN, UserRoles.DEVELOPERS, UserRoles.SUPER_ADMIN],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default routes;
