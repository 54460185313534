export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_TOGGLE_RESET_PASSWORD_MODAL =
  'AUTH_TOGGLE_RESET_PASSWORD_MODAL';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_PAGELOADING = 'AUTH_PAGELOADING';
export const AUTH_CONTENTLOADING = 'AUTH_CONTENTLOADING';
export const AUTH_TOGGLESIDEBAR = 'AUTH_TOGGLESIDEBAR';

export default {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  AUTH_USER,
  AUTH_PAGELOADING,
  AUTH_CONTENTLOADING,
  AUTH_TOGGLESIDEBAR,
  AUTH_RESET_PASSWORD,
  AUTH_TOGGLE_RESET_PASSWORD_MODAL,
};
