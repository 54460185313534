import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import NavGroup from './components/NavGroup';
import { navItems } from '../../modules/full-time/constants';

function FullTimeSidebar({ active, showSidebar }) {
  return (
    <nav className={`sidebar ${showSidebar ? 'show' : 'visible-lg'}`}>
      <div className="flex bar-top">
        <img src="https://www.terawork.com/images/logo_white.svg" alt="logo" />
      </div>
      <ul className="nav-section">
        <li className="nav-link">
          <a href="/">
            <FontAwesomeIcon icon={faHome} />
            Home
          </a>
        </li>
        {navItems.map((item) => (
          <NavGroup {...item} key={item.title} active={item.title === active} />
        ))}
      </ul>
    </nav>
  );
}

FullTimeSidebar.propTypes = {
  active: PropTypes.string.isRequired,
  showSidebar: PropTypes.bool.isRequired,
};

export default FullTimeSidebar;
