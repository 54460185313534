import { lazy } from 'react';
import { UserRoles } from '../../auth/constants';
import { NavigationTitles } from '../constants';

const ClosedModule = lazy(() => import('../close.module'));
const workstore = lazy(() => import('../pages/Workstore'));
const workstorePost = lazy(() => import('../pages/Workstore/post'));
const pendingPosts = lazy(() => import('../pages/Workstore/pending'));
const expiredPosts = lazy(() => import('../pages/Workstore/expired'));
const openPosts = lazy(() => import('../pages/Workstore/open'));

const title = NavigationTitles.workstore;

export default [
  {
    path: '/backend/workstore',
    exact: true,
    component: workstore,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      UserRoles.UTILITY,
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/workstore/pending',
    exact: true,
    component: pendingPosts,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      UserRoles.UTILITY,
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/workstore/expired',
    exact: true,
    component: expiredPosts,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/workstore/open',
    exact: true,
    component: openPosts,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/workstore/:id',
    exact: true,
    component: workstorePost,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      UserRoles.UTILITY,
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
];
