import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";

function PrivateRoute({
  module: Module,
  component: Component,
  isAuthenticated,
  user,
  ...rest
}) {
  const checkPermission = () => {
    const permissions = rest.permissions || [];
    if (!permissions || permissions.length < 1) {
      return true;
    }

    return (
      user.cognitoGroups &&
      (permissions.includes(user.cognitoGroups[0]) ||
        permissions.includes(user.cognitoGroups[1]) ||
        permissions.includes(user.cognitoGroups[2]))
    );
  };

  return rest.redirect ? (
    <Redirect to={rest.to} from={rest.path} />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Module {...props} hideHeader={rest.hideHeader} title={rest.title}>
            {checkPermission() ? (
              <Component {...props} />
            ) : (
              <div className="h4 text-center p-4">Access Denied</div>
            )}
          </Module>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  module: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
