import { SET_CASHOUT_CHARGE, SET_CASHOUT_CHARGES, SET_TOPUPS } from './actions';

const initialState = {
  cashoutCharges: null,
  cashoutChargeObj: {},
  topups: {
    processing: null,
    successful: null,
  },
};

const listCashoutCharges = (state, payload) => ({
  ...state,
  cashoutCharges: payload,
});

const setCashoutCharge = (state, payload) => {
  return {
    ...state,
    cashoutChargeObj: { ...state.cashoutChargeObj, [payload.id]: payload },
  };
};

const setTopups = (state, { type, ...payload }) => {
  return {
    ...state,
    topups: { ...state.topups, [type]: payload },
  };
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CASHOUT_CHARGES:
      return listCashoutCharges(state, payload);
    case SET_CASHOUT_CHARGE:
      return setCashoutCharge(state, payload);
    case SET_TOPUPS:
      return setTopups(state, payload);
    default:
      return state;
  }
};
