export const SET_SELLER_LIST = "SET_SELLER_LIST";
export const SET_ANNOTATIONS = "SET_ANNOTATIONS";
export const SET_DATA_SEARCH = "SET_DATA_SEARCH";
export const SET_SELLER_REPORT = "SET_SELLER_REPORT";

export const listSellers = (payload) => ({
  type: SET_SELLER_LIST,
  payload,
});

export function listAnnotations(payload) {
  return { type: SET_ANNOTATIONS, payload };
}

export function listDataSearchResult(payload) {
  return {
    type: SET_DATA_SEARCH,
    payload,
  };
}

export function listSellerReport(payload) {
  return {
    type: SET_SELLER_REPORT,
    payload,
  };
}
