export const LIST_PROFILES = "LIST_PROFILES";
export const LIST_PENDING_PROFILES = "LIST_PENDING_PROFILES";
export const LIST_PROFILE_COLLECTIONS = "LIST_PROFILE_COLLECTIONS";
export const GET_PROFILE_COLLECTION = "GET_PROFILE_COLLECTION";
export const LIST_ANNOTATIONS = "LIST_ANNOTATIONS";

export const listProfiles = (payload) => {
  return {
    type: LIST_PROFILES,
    payload,
  };
};

export const listPendingProfiles = (payload) => {
  return {
    type: LIST_PENDING_PROFILES,
    payload,
  };
};

export const listProfileCollections = (payload) => {
  return {
    type: LIST_PROFILE_COLLECTIONS,
    payload,
  };
};

export const getProfileCollection = (payload) => {
  return {
    type: GET_PROFILE_COLLECTION,
    payload,
  };
};

export const listAnnotations = (payload) => {
  return {
    type: LIST_ANNOTATIONS,
    payload,
  };
};
