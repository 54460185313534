import StorePost from '../../../model/StorePost';
import { LIST_JOB_POSTS } from './actions';

const listJobPost = (state, payload) => {
  const { data, type, ...meta } = payload;

  const postModels = data.map((item) => new StorePost(item));

  return { ...state, [type]: { data: postModels, meta } };
};

const initialState = {};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_JOB_POSTS:
      return listJobPost(state, payload);
    default:
      return state;
  }
};

export default reducer;
