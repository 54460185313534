import {
  SET_CONVERSATIONS,
  SET_DEPENDENCIES,
  SET_MESSAGES,
  SET_ONLINE_USERS,
  SET_SOCKET,
  SET_SUPPORT_LIST,
  UPDATE_CONVERSATIONS,
  SET_ADMIN_CONVERSATIONS,
  SET_ADMIN_MESSAGES,
  INVITE_TO_CHAT,
  UPDATE_MESSAGES,
  FAILED_MESSAGES,
  REMOVE_SUPPORT,
  SET_CHAT_LOGS,
  UPDATE_MY_CONVERSATION,
  DELETE_CHAT_LOG,
} from "./action-types";

const initialState = {
  dependencies: {},
  netConversions: { currencyToOthers: {}, othersToCurrency: {} },
  socket: { emit: () => {} },
  conversations: {},
  messages: {},
  supports: [],
  adminMessages: {},
  invitedUser: null,
  chatLogs: null,
};

const setDependencies = (state, payload) => {
  const currencyToOthers = {};
  const othersToCurrency = {};
  const currArr = payload.netConversions || [];

  /**
   * Groups currency into bidirectional objects of currency_id and buying_currency_id
   *
   * TAKE YOUR TIME TO READ IT VERY WELL, IT IS NOT DIFFICULT, JUST FOLLOW LINE BY LINE
   * You can refactor to a cleaner and more scalable method
   * I choose this method because the input has potential to be a larget data set
   * THE TIME COMPLEXITY IS O(n)
   *
   * still stuck? reach "yomlateef@yahoo.com"
   */

  for (let i = 0; i < currArr.length; i++) {
    const { buyingCurrencyId, currencyId } = currArr[i];

    //group Currency to others
    if (currencyToOthers[currencyId]) {
      currencyToOthers[currencyId] = {
        ...currencyToOthers[currencyId],
        [buyingCurrencyId]: currArr[i],
      };
    } else {
      currencyToOthers[currencyId] = { [buyingCurrencyId]: currArr[i] };
    }

    // //group Others to Currency
    if (othersToCurrency[buyingCurrencyId]) {
      othersToCurrency[buyingCurrencyId] = {
        ...othersToCurrency[buyingCurrencyId],
        [currencyId]: currArr[i],
      };
    } else {
      othersToCurrency[buyingCurrencyId] = { [currencyId]: currArr[i] };
    }
  }

  return {
    ...state,
    dependencies: payload,
    netConversions: { othersToCurrency, currencyToOthers },
  };
};

const setSocket = (state, payload) => {
  return { ...state, socket: payload };
};

const setConversations = (state, payload) => {
  const sortedConversations = payload.convs.sort(
    (a, b) => b.timestamp - a.timestamp
  );

  return {
    ...state,
    conversations: { ...state.conversations, convs: sortedConversations },
  };
};

const setAdminConversations = (state, payload) => {
  const sortedConversations = payload.convs.sort(
    (a, b) => b.timestamp - a.timestamp
  );

  return {
    ...state,
    conversations: { ...state.conversations, adminConvs: sortedConversations },
  };
};

const setOnlineUsers = (state, payload) => {
  return {
    ...state,
    conversations: { ...state.conversations, conns: payload },
  };
};

const setMessages = (state, payload) => {
  const sortedMessages = payload.messages.sort(
    (a, b) => a.timestamp - b.timestamp
  );
  return {
    ...state,
    messages: { ...state.messages, [payload.conversationId]: sortedMessages },
  };
};

const updateMessages = (state, payload) => {
  const messages = state.messages[payload.conversationId];
  const newMessages = [...messages, payload];

  return {
    ...state,
    messages: { ...state.messages, [payload.conversationId]: newMessages },
  };
};

const failedMessage = (state, payload) => {
  const messages = state.messages[payload.conversationId];

  const filteredMessages = messages.filter((msg) => msg.PK !== payload.PK);

  return {
    ...state,
    messages: { ...state.messages, [payload.conversationId]: filteredMessages },
  };
};

const setAdminMessages = (state, payload) => {
  const sortedMessages = payload.messages.sort(
    (a, b) => b.timestamp - a.timestamp
  );
  return {
    ...state,
    adminMessages: {
      ...state.adminMessages,
      [payload.conversationId]: sortedMessages,
    },
  };
};

const setSupportList = (state, payload) => {
  return {
    ...state,
    supports: payload,
  };
};

const removeSupport = (state, payload) => {
  const removedSupports = state.supports.filter(
    (support) => support.id !== payload.id
  );

  return { ...state, supports: removedSupports };
};

const updateConversations = (state, payload) => {
  const firstConversation = state.conversations.convs[0];

  if (firstConversation.id !== payload.conversationId) {
    //if the conversation exists, edit it, else create a new one
    const convoExist = state.conversations.convs.some(
      (conv) => conv.id === payload.conversationId
    );

    if (convoExist) {
      const filteredConvs = state.conversations.convs.filter(
        (conv) => conv.id !== payload.conversationId
      );
      const newConversations = [payload, ...filteredConvs];

      return {
        ...state,
        conversations: { ...state.conversations, convs: newConversations },
      };
    }

    return {
      ...state,
      conversations: {
        ...state.conversations,
        convs: [payload, ...state.conversations.convs],
      },
    };
  }

  return state;
};

const inviteUserToChat = (state, payload) => {
  return { ...state, invitedUser: payload };
};

const updateMyConversation = (state, payload) => {
  const conversations = state.conversations.adminConvs || [];

  const filteredConvos = conversations.filter(
    (conv) => +conv.conversationId !== +payload.conversationId
  );

  return {
    ...state,
    conversations: {
      ...state.conversations,
      adminConvs: [payload, ...filteredConvos],
    },
  };
};

const setChatLogs = (state, payload) => ({ ...state, chatLogs: payload });

const deleteChatLog = (state, payload) => {
  const undeletedLogs = [...state.chatLogs].filter(
    (log) => log.id !== payload.id
  );

  return { ...state, chatLogs: undeletedLogs };
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case SET_DEPENDENCIES:
      return setDependencies(state, payload);
    case SET_SOCKET:
      return setSocket(state, payload);
    case SET_CONVERSATIONS:
      return setConversations(state, payload);
    case SET_ONLINE_USERS:
      return setOnlineUsers(state, payload);
    case SET_MESSAGES:
      return setMessages(state, payload);
    case SET_SUPPORT_LIST:
      return setSupportList(state, payload);
    case UPDATE_CONVERSATIONS:
      return updateConversations(state, payload);
    case SET_ADMIN_CONVERSATIONS:
      return setAdminConversations(state, payload);
    case SET_ADMIN_MESSAGES:
      return setAdminMessages(state, payload);
    case INVITE_TO_CHAT:
      return inviteUserToChat(state, payload);
    case UPDATE_MY_CONVERSATION:
      return updateMyConversation(state, payload);
    case UPDATE_MESSAGES:
      return updateMessages(state, payload);
    case FAILED_MESSAGES:
      return failedMessage(state, payload);
    case REMOVE_SUPPORT:
      return removeSupport(state, payload);
    case SET_CHAT_LOGS:
      return setChatLogs(state, payload);
    case DELETE_CHAT_LOG:
      return deleteChatLog(state, payload);
    default:
      return state;
  }
};

export default reducer;
