import { Center, Text, VStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import { useMemo } from 'react';

const generateRandomQuote = () => {
  const quotes = [
    {
      quote:
        'Opportunity is missed by most people because it is dressed in overalls and looks like work.',
      author: 'Thomas Edison',
    },
    {
      quote:
        'Success usually comes to those who are too busy to be looking for it.',
      author: 'Henry David Thoreau',
    },
    {
      quote:
        'The only place where success comes before work is in the dictionary.',
      author: 'Vidal Sassoon',
    },
    {
      quote:
        'Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.',
      author: 'Albert Schweitzer',
    },
    {
      quote: 'The secret of getting ahead is getting started.',
      author: 'Mark Twain',
    },
    {
      quote: 'The future depends on what you do today.',
      author: 'Mahatma Gandhi',
    },
    {
      quote: "Don't watch the clock; do what it does. Keep going.",
      author: 'Sam Levenson',
    },
    {
      quote:
        "The harder you work for something, the greater you'll feel when you achieve it.",
      author: 'Unknown',
    },
    {
      quote: 'Success is not in what you have, but who you are.',
      author: 'Bo Bennett',
    },
    {
      quote: "Believe you can and you're halfway there.",
      author: 'Theodore Roosevelt',
    },
    {
      quote: "You miss 100% of the shots you don't take.",
      author: 'Wayne Gretzky',
    },
    {
      quote: 'I find that the harder I work, the more luck I seem to have.',
      author: 'Thomas Jefferson',
    },
    { quote: 'Work cannot kill you.', author: 'Femi Taiwo' },
    {
      quote:
        'Two people went to a village, only their King wears shoes... "ask the author for the rest"',
      author: 'Femi Taiwo',
    },
    {
      quote: 'I never dreamed about success. I worked for it.',
      author: 'Estée Lauder',
    },
    {
      quote:
        'Even if you are on the right track, you’ll get run over if you just sit there.',
      author: 'Will Rogers',
    },
  ];

  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
};

export const PageLoader = () => {
  const { quote, author } = useMemo(generateRandomQuote, []);

  return (
    <Center
      pos="fixed"
      top={0}
      left={0}
      zIndex={3000}
      w="100vw"
      h="100vh"
      bg="#0007"
    >
      <VStack spacing="4">
        <Image
          src="https://www.terawork.com/images/buyer_pod_bg3.png"
          w="100px"
          className="logo-loader"
        />
        <VStack>
          <Text color="gray.300" fontSize="md" textAlign="center" maxW="500px">
            ‘{quote}‘
          </Text>
          <Text
            color="gray.300"
            fontSize="sm"
            textAlign="center"
            fontStyle="italic"
          >
            - {author}
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
};
