import closedRoutes from '../modules/close/routes/index';
import authRoutes from '../modules/auth/routes';
import openRoutes from '../modules/open/routes';
import talentRoutes from '../modules/full-time/routes';

const allRoutes = [
  ...closedRoutes,
  ...talentRoutes,
  ...openRoutes,
  ...authRoutes,
];
export default allRoutes;
