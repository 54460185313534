import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAdminChats } from "../modules/close/pages/Messaging/redux/action";
import AdminChat from "../modules/close/pages/Messaging/traits/AdminChat";

export const useAdminChat = () => {
  const chats = useSelector((state) => state.messaging.adminChats);
  const adminUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const setNewMessageIndicator = useCallback(
    (msg) => {
      if (msg?.receiverId === adminUser?.id) {
        const newConvs = chats.map((chat) => {
          if (chat.id === msg.conversationId) {
            return {
              ...chat,
              newMessage: true,
            };
          }
          return chat;
        });

        dispatch(listAdminChats({ convs: newConvs }));
      }
    },
    [chats, adminUser?.id, dispatch]
  );

  const markAsRead = useCallback(
    (conversationId) => {
      //Update admin Last seen
      const adminChat = new AdminChat();
      adminChat.updateAdminLastSeen(conversationId);

      //remove unread message flag
      const updatedChats = chats.map((chat) => {
        if (chat.id === conversationId) {
          return { ...chat, newMessage: false };
        }

        return chat;
      });

      dispatch(listAdminChats({ convs: updatedChats }));
    },
    [chats, dispatch]
  );

  return {
    setNewMessageIndicator,
    markAsRead,
  };
};
