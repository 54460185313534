import Axios from 'axios';
import Notify from '../../utils/notify';
import { setDependencies } from './store/actions';
import Transformer from '../../utils/transformer';

function transformResponse(params) {
  return Transformer.fetch(params);
}

export function fetchDependencies() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const url =
        'https://8l4cyxccdh.execute-api.eu-west-1.amazonaws.com/Prod/resources';
      Axios.get(url)
        .then((res) => {
          dispatch(setDependencies(transformResponse(res.data.data)));
        })
        .catch((err) => {
          const { response } = err;
          if (typeof response !== 'undefined') {
            const statusCode = response.status;
            const data = {
              error: response.data.error
                ? response.data.error.message
                : response.statusText,
              statusCode,
            };
            Notify.error(data.error);
            return reject(data);
          }
          Notify.error('oops!');
          return reject(err);
        });
    });
}
