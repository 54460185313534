import { faComment, faStore } from '@fortawesome/free-solid-svg-icons';

export const OpenNavigationTitle = {
  home: 'Home',
  messages: 'Messages',
  workstore: 'WorkStore',
};

export const navItems = [
  {
    title: OpenNavigationTitle.messages,
    links: [
      {
        title: 'Flagged',
        url: '/messages/flagged',
      },
      {
        title: 'User Conversations',
        url: '/messages/conversations',
      },
      {
        title: 'Support Requests',
        url: '/messages/supports',
      },
      {
        title: 'Missed',
        url: '/messages/missed',
      },
    ],
    icon: faComment,
  },
  {
    title: OpenNavigationTitle.workstore,
    links: [
      {
        title: 'Workstore',
        url: '/workstore',
      },
      {
        title: 'Pending',
        url: '/workstore/pending',
      },
    ],
    icon: faStore,
  },
];
