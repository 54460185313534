import { toast } from 'react-toastify';

const Notify = {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: 'toast-success-container',
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      autoClose: 5000,
      className: 'toast-success-container',
      ...options,
    });
  },
  simple(msg, options = {}) {
    return toast(msg, {
      ...options,
      // className: 'toast-error-container',
    });
  },
};

export default Notify;
