import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import { authUser } from '../modules/auth/store/actions';
import Auth from '../utils/auth';

import { fetchDependencies } from '../modules/settings/services';
import SocketHandler from './socketHandler';
import Transformer from '../utils/transformer';
import { getAuthUser } from '../modules/auth/services';
import { getUsers } from '../modules/close/pages/users/service';

class Layout extends PureComponent {
  async UNSAFE_componentWillMount() {
    const { isAuthenticated, user, dispatch } = this.props;

    if (isAuthenticated && !user.email) {
      const auth = new Auth();
      const authenticatedUser = auth.getUser();
      const transformedData = Transformer.fetch(authenticatedUser);
      auth.tokenRefreshInterval();

      const userDetails = await dispatch(getAuthUser());
      dispatch(
        authUser({
          ...transformedData,
          ...userDetails,
          chatName: `TERAWORK-${transformedData.givenName}`,
        })
      );
      dispatch(fetchDependencies());
      dispatch(getUsers({}));
    }
  }

  render() {
    const { children, pageLoading } = this.props;
    toast.configure();

    return (
      <>
        <ToastContainer />
        <SocketHandler />
        <LoadingOverlay active={pageLoading} spinner>
          {children}
        </LoadingOverlay>
      </>
    );
  }
}

Layout.displayName = 'Layout';

Layout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool,
};

Layout.defaultProps = {
  pageLoading: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  pageLoading: state.auth.pageLoading,
  showSidebar: state.auth.showSidebar,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Layout);
