import { LIST_ONGOING_WORKS, LIST_ORDERS } from './actions';

const initialState = {
  ongoingWorks: {},
  orders: {},
};

const listOngoingWorks = (state = initialState, payload) => {
  const { type, ...data } = payload;

  return { ...state, ongoingWorks: { ...state.ongoingWorks, [type]: data } };
};

const listOrders = (state, payload) => {
  const { type, ...data } = payload;

  return {
    ...state,
    orders: {
      ...state.orders,
      [type]: data,
    },
  };
};

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LIST_ONGOING_WORKS:
      return listOngoingWorks(state, payload);
    case LIST_ORDERS:
      return listOrders(state, payload);
    default:
      return state;
  }
};

export default reducer;
