export const LIST_ADMIN_CHATS = "LIST_ADMIN_CHATS";
export const LIST_CHAT_MESSAGES = "LIST_CHAT_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const UPDATE_ADMIN_CONVERSATION = "UPDATE_ADMIN_CONVERSATION";

export const listAdminChats = (payload) => {
  return {
    type: LIST_ADMIN_CHATS,
    payload,
  };
};

export const listChatMessages = (payload) => {
  return {
    type: LIST_CHAT_MESSAGES,
    payload,
  };
};

export const addMessage = (payload) => {
  return {
    type: ADD_MESSAGE,
    payload,
  };
};

export const updateAdminConversation = (payload) => {
  return {
    type: UPDATE_ADMIN_CONVERSATION,
    payload,
  };
};
