export const GET_TALENTS = 'GET_TALENTS';
export const GET_TALENT_SEARCH = 'GET_TALENT_SEARCH';
export const GET_TALENT = 'GET_TALENT';

export const listTalents = (payload) => {
  return {
    type: GET_TALENTS,
    payload,
  };
};

export const listTalentSearch = (payload) => {
  return {
    type: GET_TALENT_SEARCH,
    payload,
  };
};

export const getTalent = (payload) => {
  return {
    type: GET_TALENT,
    payload,
  };
};
