import { lazy } from "react";
import { UserRoles } from "../auth/constants";
import { OpenNavigationTitle } from "./constants";

const OpenModule = lazy(() => import("./open.module"));
const Home = lazy(() => import("./pages/home"));
const Flagged = lazy(() => import("./pages/messages/flagged"));
const Missed = lazy(() => import("./pages/messages/missed"));
const conversations = lazy(() =>
  import("../close/pages/Messaging/conversations")
);
const supports = lazy(() => import("../close/pages/Messaging/support"));

const workstore = lazy(() => import("../close/pages/Workstore"));
const pendingJobPost = lazy(() => import("../close/pages/Workstore/pending"));

const routes = [
  {
    path: "/home",
    exact: true,
    component: Home,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      UserRoles.UTILITY,
    ],
    title: "Home",
    module: OpenModule,
    auth: true,
  },
  {
    path: "/messages/flagged",
    exact: true,
    component: Flagged,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      UserRoles.UTILITY,
    ],
    title: OpenNavigationTitle.messages,
    module: OpenModule,
    auth: true,
  },
  {
    path: "/messages/missed",
    exact: true,
    component: Missed,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      UserRoles.UTILITY,
    ],
    title: OpenNavigationTitle.messages,
    module: OpenModule,
    auth: true,
  },
  {
    path: "/messages/conversations",
    exact: true,
    component: conversations,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      UserRoles.UTILITY,
    ],
    title: OpenNavigationTitle.messages,
    module: OpenModule,
    auth: true,
  },
  {
    path: "/messages/supports",
    exact: true,
    component: supports,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      UserRoles.UTILITY,
    ],
    title: OpenNavigationTitle.messages,
    module: OpenModule,
    auth: true,
  },
  {
    path: "/workstore",
    exact: true,
    component: workstore,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      UserRoles.UTILITY,
    ],
    title: OpenNavigationTitle.workstore,
    module: OpenModule,
    auth: true,
  },
  {
    path: "/workstore/pending",
    exact: true,
    component: pendingJobPost,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      UserRoles.UTILITY,
    ],
    title: OpenNavigationTitle.workstore,
    module: OpenModule,
    auth: true,
  },
];

export default routes;
