import Service from "../../../model/Service";
import {
  GET_PROFILE_COLLECTION,
  LIST_ANNOTATIONS,
  LIST_PENDING_PROFILES,
  LIST_PROFILES,
  LIST_PROFILE_COLLECTIONS,
} from "./actions";

const initialState = {
  pending: null,
  profiles: null,
  profileCollections: {},
  profileCollectionList: null,
  annotations: null,
};

const mapToModel = (profiles = []) => {
  return profiles.map((profile) => new Service(profile));
};

const listProfiles = (state, { data, type, ...meta }) => {
  const profileModel = mapToModel(data);
  return { ...state, [type]: { data: profileModel, ...meta } };
};

const listPendingProfiles = (state, { data, ...meta }) => {
  const profileModel = mapToModel(data);

  return { ...state, pending: { data: profileModel, ...meta } };
};

const listProfileCollections = (state, payload) => {
  return { ...state, profileCollectionList: payload };
};

const getProfileCollection = (state, payload) => {
  console.log(payload);
  return {
    ...state,
    profileCollections: { ...state.profileCollections, [payload.id]: payload },
  };
};

const listAnnotations = (state, payload) => {
  return { ...state, annotations: payload };
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_PROFILES:
      return listProfiles(state, payload);
    case LIST_PENDING_PROFILES:
      return listPendingProfiles(state, payload);
    case LIST_PROFILE_COLLECTIONS:
      return listProfileCollections(state, payload);
    case GET_PROFILE_COLLECTION:
      return getProfileCollection(state, payload);
    case LIST_ANNOTATIONS:
      return listAnnotations(state, payload);
    default:
      return state;
  }
};

export default reducer;
