import Model from '../../../utils/Model';

class Service extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  initialize(props) {
    super.initialize(props);

    this.status = props.approved ? 'Public' : 'Pending';
    this.statusColor = props.approved ? 'green' : 'red';

    this.title = props.serviceOverviewHeader || '-';
    this.description = props.serviceOverviewBody || '-';
    this.category = props.category?.shortName ?? '-';
    this.label = props.workLabel || '';
  }
}

export default Service;
