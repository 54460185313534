import { FLAGGED_MESSAGES, MISSED_MESSAGES } from './action-types';
import Message from '../model/message';

const initialState = {
  flagged: {
    data: null,
    meta: {},
  },
  missed: [],
};

const listFlagged = (state, payload) => {
  const { data, ...meta } = payload;

  const messages = data.map((message) => new Message(message));

  return { ...state, flagged: { data: messages, meta } };
};

const listMissed = (state, payload) => {
  return { ...state, missed: payload };
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case FLAGGED_MESSAGES:
      return listFlagged(state, payload);
    case MISSED_MESSAGES:
      return listMissed(state, payload);
    default:
      return state;
  }
};

export default reducer;
