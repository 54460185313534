import * as API from '../../../../utils/api';
import { authPageLoading } from '../../../auth/store/actions';
import { listUsers } from '../../store/actions';

export function getUsers({ url = '/users' }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(authPageLoading(true));

      API.getReq(
        url,
        (res) => {
          dispatch(authPageLoading(false));
          dispatch(listUsers(res.users));
        },
        (err) => {
          dispatch(authPageLoading(false));
          reject(err);
        }
      );
    });
}

export function createUser({ url = '/users/create', params }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(authPageLoading(true));

      API.postReq(
        url,
        params,
        (res) => {
          dispatch(authPageLoading(false));
          return resolve(res);
        },
        (err) => {
          dispatch(authPageLoading(false));
          reject(err);
        }
      );
    });
}
