import React, { useState } from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import { authLogout } from "../../modules/auth/store/actions";

function Header({
  dispatch,
  title,
  firstName = "",
  lastName = "",
  navItems = [],
  toggleSideBar,
}) {
  const initals = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  const [showNav, setShowNav] = useState(false);
  const history = useHistory();

  const logout = () => {
    setShowNav(!showNav);
    dispatch(authLogout());
  };

  const goBack = () => {
    history.goBack();
  };

  const renderHeaderNav = () => {
    const activeNav = navItems.find((nav) => nav.title === title);

    return activeNav
      ? activeNav.links.map((nav) => (
          // eslint-disable-next-line react/jsx-indent
          <NavLink
            to={nav.url}
            key={nav.url}
            className="nav-item"
            exact
            activeClassName="active-nav"
          >
            {nav.title}
          </NavLink>
        ))
      : null;
  };

  return (
    <header className="header white-bg">
      <div className="header-top p-rel">
        <div className="flex al-c">
          <button
            type="button"
            className="button-link marg-r-0-5 visible-lg"
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            type="button"
            className="button-link marg-r-0-5 visible-sm"
            onClick={toggleSideBar}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <h1 className="page-title marg-0">{title}</h1>
        </div>
        <button
          type="button"
          className="user-initial border-round pointer bold"
          onClick={() => setShowNav(!showNav)}
        >
          {initals}
        </button>
        {showNav && (
          <nav className="header-top-nav p-abs shadow-small pointer">
            <ul>
              <a href="#0" onClick={logout}>
                Logout
              </a>
            </ul>
          </nav>
        )}
      </div>
      <div className="header-nav">{renderHeaderNav()}</div>
    </header>
  );
}

export default Header;
