import { lazy } from "react";
import { NavigationTitles } from "../constants";

const ClosedModule = lazy(() => import("../close.module"));
const sellers = lazy(() => import("../pages/sellers"));
const notPublic = lazy(() => import("../pages/sellers/notpublic"));
const pending = lazy(() => import("../pages/sellers/pending"));
const notVerified = lazy(() => import("../pages/sellers/notverified"));
const publicProflie = lazy(() => import("../pages/sellers/public"));
const promoProfile = lazy(() => import("../pages/sellers/profile/promo"));
const seller = lazy(() => import("../pages/sellers/seller"));
const profile = lazy(() => import("../pages/sellers/profile"));
const reviews = lazy(() => import("../pages/sellers/reviews"));
const pendingReviews = lazy(() => import("../pages/sellers/reviews/pending"));

const sellersSummary = lazy(() => import("../pages/talents/seller-summary"));
const sellersVitals = lazy(() => import("../pages/talents/seller-vitals"));
const sellersScreening = lazy(() =>
  import("../pages/talents/seller-screening")
);

const sellerReport = lazy(() => import("../pages/sellers/report"));

const title = NavigationTitles.sellers;

const routes = [
  {
    path: "/backend/sellers",
    exact: true,
    component: sellers,
    permissions: [],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/public",
    exact: true,
    component: publicProflie,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/not-public",
    exact: true,
    component: notPublic,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/pending",
    exact: true,
    component: pending,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/not-verified",
    exact: true,
    component: notVerified,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/report",
    exact: true,
    component: sellerReport,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/promo-profile/:id",
    exact: true,
    component: promoProfile,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/:id",
    exact: true,
    component: seller,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers/:id/:profileId",
    exact: true,
    component: profile,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers-reviews",
    exact: true,
    component: reviews,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers-reviews/pending",
    exact: true,
    component: pendingReviews,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers-summary",
    exact: true,
    component: sellersSummary,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers-vitals",
    exact: true,
    component: sellersVitals,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/sellers-screening",
    exact: true,
    component: sellersScreening,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default routes;
