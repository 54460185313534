import { GET_TALENTS, GET_TALENT_PROFILES } from './actionTypes';

export const listTalents = (payload) => {
  return {
    type: GET_TALENTS,
    payload,
  };
};

export const listTalentProfiles = (payload) => {
  return {
    type: GET_TALENT_PROFILES,
    payload,
  };
};
