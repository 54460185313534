import {
  LIST_PROFILE_TAGS,
  LIST_SELLER_TAGS,
  SET_PROFILE_TAG,
  SET_SELLER_TAG,
} from './action-types';

const initialState = {
  profile: {
    list: null,
    tags: {},
  },
  seller: {
    list: null,
    tags: {},
  },
};

const listProfileTags = (state, payload) => {
  return { ...state, profile: { ...state.profile, list: payload } };
};

const setProfileTag = (state, payload) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      tags: { ...state.profile.tags, [`tag_${payload.id}`]: payload },
    },
  };
};

const listSellerTags = (state, payload) => {
  return { ...state, seller: { ...state.seller, list: payload } };
};

const setSellerTag = (state, payload) => {
  return {
    ...state,
    seller: {
      ...state.seller,
      tags: { ...state.seller.tags, [`tag_${payload.id}`]: payload },
    },
  };
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_SELLER_TAGS:
      return listSellerTags(state, payload);
    case SET_SELLER_TAG:
      return setSellerTag(state, payload);
    case LIST_PROFILE_TAGS:
      return listProfileTags(state, payload);
    case SET_PROFILE_TAG:
      return setProfileTag(state, payload);
    default:
      return state;
  }
};

export default reducer;
