import * as DOMPurify from 'dompurify';

export const duration = (value) => {
  return value > 24 ? `${Math.floor(value / 24)} days` : `${value} days`;
};

export const packageDuration = (time, unit) => {
  const unitString = unit === 2 ? 'day' : 'hour';
  return `${time} ${unitString}${time > 1 ? 's' : ''}`;
};

export const pluralizeUnit = (value, unit) => {
  return `${value} ${unit}` + (value > 1 ? 's' : '');
};

export const getBooleanValue = (
  value,
  truthString = 'Yes',
  falseString = 'No'
) => {
  return !!value ? truthString : falseString;
};

export const separateWithComma = (value) => {
  return value ? Number(value).toLocaleString() : '';
};

export const trimText = (text = '', limit) => {
  return text.length > limit ? `${text.substring(0, limit)} ...` : text;
};

export const convertToShortPriceUnit = (price) => {
  if (price >= 1000000000) {
    return `${price / 1000000000}B`;
  } else if (price >= 1000000) {
    return `${price / 1000000}M`;
  } else if (price >= 1000) {
    return `${price / 1000}K`;
  } else {
    return price;
  }
};

export const stringToArray = (string) => {
  return typeof string === 'string' ? JSON.parse(string) : string;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + ' ' + match[2] + ' ' + match[3];
  }

  return String(phoneNumber || '').replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3');
};

export const removeHTMLTags = (text = '') => {
  let dom = new DOMParser().parseFromString(text, 'text/html');
  let finalText = dom.body.textContent.replace(/(\r\n|\n|\r)/gm, '');
  return DOMPurify.sanitize(finalText);
};
