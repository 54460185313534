import { faUserCog, faSuitcase } from '@fortawesome/free-solid-svg-icons';

export const NavigationTitles = {
  talents: 'Talents',
  jobRequests: 'Job Requests',
  companies: 'Companies',
  jobPosts: 'Job Posts',
};

export const navItems = [
  {
    title: NavigationTitles.talents,
    links: [
      {
        title: 'All Talents',
        url: '/fulltime/talents',
      },
      {
        title: 'Search Talent',
        url: '/fulltime/talents/search',
      },
      {
        title: 'Folders / Collections',
        url: '/fulltime/folders',
      },
      {
        title: 'Annotations',
        url: '/fulltime/annotations',
      },
    ],
    icon: faUserCog,
  },
  {
    title: NavigationTitles.jobRequests,
    links: [
      {
        title: 'Client Requests',
        url: '/fulltime/job-requests',
      },
    ],
    icon: faSuitcase,
  },
  {
    title: NavigationTitles.jobPosts,
    links: [
      {
        title: 'All Posts',
        url: '/fulltime/job-posts',
      },
    ],
    icon: faSuitcase,
  },
];
