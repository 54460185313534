import { lazy } from 'react';
import { NavigationTitles } from '../constants';

const ClosedModule = lazy(() => import('../close.module'));
const AllJobs = lazy(() => import('../pages/employ'));
const Job = lazy(() => import('../pages/employ/job'));

const title = NavigationTitles.employ;

export default [
  {
    path: '/backend/employ',
    exact: true,
    component: AllJobs,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/employ/:id',
    exact: true,
    component: Job,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
];
