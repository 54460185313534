import { lazy } from 'react';
import { NavigationTitles } from '../constants';
import { UserRoles } from '../../auth/constants';

const ClosedModule = lazy(() => import('../close.module'));
const currencies = lazy(() => import('../pages/Payments/currencies'));
const currency = lazy(() => import('../pages/Payments/currencies/currency'));
const currencyRates = lazy(() => import('../pages/Payments/currencies/rates'));
const netRates = lazy(() =>
  import('../pages/Payments/currencies/net-conversions')
);
const editRates = lazy(() => import('../pages/Payments/currencies/rates/edit'));
const editFullRates = lazy(() =>
  import('../pages/Payments/currencies/rates/rate')
);
const createCurrency = lazy(() =>
  import('../pages/Payments/currencies/create')
);
const convertCurrency = lazy(() =>
  import('../pages/Payments/currencies/converter')
);

const payments = lazy(() => import('../pages/Payments/Accounts'));
const payment = lazy(() => import('../pages/Payments/Accounts/show'));
const cashouts = lazy(() => import('../pages/Payments/Accounts/cashout'));
const cashoutCharges = lazy(() =>
  import('../pages/Payments/Accounts/cashout/charges')
);
const topup = lazy(() => import('../pages/Payments/topup'));

const title = NavigationTitles.payments;

const routes = [
  {
    path: '/backend/currency',
    exact: true,
    component: currencies,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },

  {
    path: '/backend/currency/create',
    exact: true,
    component: createCurrency,
    permissions: ['SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/currency/converter',
    exact: true,
    component: convertCurrency,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/currency/rates',
    exact: true,
    component: currencyRates,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/currency/rates/edit',
    exact: true,
    component: editFullRates,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/currency/rates/edit-rate',
    exact: true,
    component: editRates,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/currency/net-conversions',
    exact: true,
    component: netRates,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/currency/:id',
    exact: true,
    component: currency,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/payments',
    exact: true,
    component: payments,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
      'Developers',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/payments/cashouts',
    exact: true,
    component: cashouts,
    permissions: [],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/payments/topup',
    exact: true,
    component: topup,
    permissions: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN, UserRoles.DEVELOPERS],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/payments/cashout-charges',
    exact: true,
    component: cashoutCharges,
    permissions: ['Admin', 'SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/payments/:id',
    exact: true,
    component: payment,
    permissions: ['Admin', 'SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default routes;
