import * as API from "../../utils/api";
import { authPageLoading } from "./store/actions";

export const getAuthUser = () => {
  const url = "/users/auth";

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(authPageLoading(true));

      API.getReq(
        url,
        (res) => {
          dispatch(authPageLoading(false));
          resolve(res.user);
        },
        (err) => {
          dispatch(authPageLoading(false));
          reject(err);
        }
      );
    });
};
