import { lazy } from 'react';
import ClosedModule from '../close.module';
import { NavigationTitles } from '../constants';
const allAdminChat = lazy(() => import('../pages/Messaging/admin-chat'));
const adminChat = lazy(() => import('../pages/Messaging/admin-chat/chat'));
const broadcasts = lazy(() => import('../pages/Messaging/broadcasts'));
const profanityChecker = lazy(() =>
  import('../pages/Messaging/profanity-checker')
);

const messagingRoutes = [
  {
    path: '/backend/messages/admin-chat',
    exact: true,
    component: allAdminChat,
    permissions: [],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/messages/admin-chat/:username',
    exact: true,
    component: adminChat,
    permissions: [],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
    hideHeader: true,
  },
  {
    path: '/backend/messages/broadcasts',
    exact: true,
    component: broadcasts,
    permissions: [],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/messages/profanity-checker',
    exact: true,
    component: profanityChecker,
    permissions: [],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
];

export default messagingRoutes;
