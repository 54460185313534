export const UserRoles = {
  SUPER_ADMIN: 'SuperAdmin',
  ADMIN: 'Admin',
  DEVELOPERS: 'Developers',
  CUSTOMER_SUPPORT: 'Customer_Support',
  MARKETING: 'Marketing',
  OPERATIONS: 'Operations',
  UTILITY: 'Utility',
  TALENT_LEAD: 'Talent_Lead',
  TALENT_SPECIALIST: 'Talent_Specialist',
};

export const transactionCode = [
  {
    name: 'Hold',
    code: 201,
    id: 201,
  },
  {
    name: 'Actual',
    code: 202,
    id: 202,
  },
  {
    name: 'CashOut',
    code: 111,
    id: 111,
  },
  {
    name: 'Refund:Offer Rejected',
    code: 205,
    id: 205,
  },
];

export const statusColor = {
  pending: 'grey',
  active: 'green',
  decline: 'red',
};
