import { combineReducers } from 'redux';

import auth from '../modules/auth/store/reducers';
import close from '../modules/close/store/reducers';
import open from '../modules/open/store/reducers';
import settings from '../modules/settings/store/reducers';
import seller from '../modules/close/pages/sellers/redux/reducer';
import tags from '../modules/close/pages/tags/redux/reducer';
import profiles from '../modules/close/pages/seller-profiles/redux/reducer';
import workstore from '../modules/close/pages/Workstore/redux/reducer';
import transactions from '../modules/close/pages/Transactions/redux/reducer';
import finance from '../modules/close/pages/finance/redux/reducer';
import employ from '../modules/close/pages/employ/redux/reducer';
import messaging from '../modules/close/pages/Messaging/redux/reducer';
import { reducer as marketplace } from '../modules/close/pages/marketplace/redux';
import { reducer as talents } from '../modules/close/pages/talents/redux';
import { reducer as payments } from '../modules/close/pages/Payments/redux';
import { reducer as fullTimeTalent } from '../modules/full-time/pages/talents/redux/reducer';

export default combineReducers({
  auth,
  close,
  open,
  settings,
  seller,
  tags,
  profiles,
  workstore,
  transactions,
  finance,
  employ,
  marketplace,
  talents,
  messaging,
  payments,
  fullTimeTalent,
});
