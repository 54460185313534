import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  AUTH_USER,
  AUTH_PAGELOADING,
  AUTH_CONTENTLOADING,
  AUTH_RESET_PASSWORD,
  AUTH_TOGGLE_RESET_PASSWORD_MODAL,
  AUTH_TOGGLESIDEBAR,
} from "./action-types";
import Auth from "../../../utils/auth";

const initialState = {
  isAuthenticated: false,
  pageLoading: false,
  contentLoading: false,
  showSideBar: false,
  resetPasswordModal: false,
  user: {},
};

const auth = new Auth();

const setUser = (state, payload = {}) => {
  return { ...state, user: { ...state.user, ...payload } };
};

const login = (state, payload) => {
  return {
    ...state,
    isAuthenticated: true,
    user: payload.user,
  };
};

const resetPassword = (state, payload) => {
  return {
    ...state,
    resetPassword: payload,
    resetPasswordModal: true,
  };
};
const toggleResetPasswordModal = (state) => {
  return {
    ...state,
    resetPasswordModal: !state.resetPasswordModal,
  };
};

const checkAuth = (state, payload) => {
  const newState = { ...state, isAuthenticated: payload };
  return newState;
};

const logout = (state) => {
  auth.logout();

  return {
    ...state,
    isAuthenticated: false,
  };
};

const togglePageLoading = (state, payload) => {
  return {
    ...state,
    pageLoading: payload,
  };
};

const toggleContentLoading = (state, payload) => {
  return {
    ...state,
    contentLoading: payload,
  };
};

const toggleSidebar = (state) => {
  return {
    ...state,
    showSideBar: !state.showSideBar,
  };
};

export const getAuth = (state) => state.auth.isAuthenticated;

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case AUTH_REFRESH_TOKEN:
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_CHECK:
      return checkAuth(state, payload);
    case AUTH_LOGOUT:
      return logout(state);
    case AUTH_USER:
      return setUser(state, payload);
    // case AUTH_TOGGLESIDEBAR:
    //   return toggleSidebar(state);
    case AUTH_PAGELOADING:
      return togglePageLoading(state, payload);
    case AUTH_CONTENTLOADING:
      return toggleContentLoading(state, payload);
    case AUTH_RESET_PASSWORD:
      return resetPassword(state, payload);
    case AUTH_TOGGLE_RESET_PASSWORD_MODAL:
      return toggleResetPasswordModal(state);
    case AUTH_TOGGLESIDEBAR:
      return toggleSidebar(state);
    default:
      return state;
  }
};

export default reducer;
