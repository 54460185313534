export const SET_BANKS = 'SET_BANKS';
export const SET_FAQS = 'SET_FAQS';
export const SET_JOB_TITLES = 'SET_JOB_TITLE';
export const SET_AFFILIATE = 'SET_AFFILIATE';
export const SET_AFFILIATES_LIST = 'SET_AFFILIATES_LIST';

export const listBanks = (payload) => ({
  type: SET_BANKS,
  payload,
});

export const setFaqs = (payload) => ({
  type: SET_FAQS,
  payload,
});

export const listJobTitle = (payload) => ({
  type: SET_JOB_TITLES,
  payload,
});

export const setAffiliate = (payload) => ({
  type: SET_AFFILIATE,
  payload,
});

export const setAffiliatesList = (payload) => ({
  type: SET_AFFILIATES_LIST,
  payload,
});
