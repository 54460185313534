import Model from '../../../utils/Model';

class Message extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  initialize(props) {
    super.initialize(props);

    this.sender = props.username || '';
    this.senderType = props.userType || '';
    this.message = props.message || '';
  }
}

export default Message;
