import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';

function NavGroup({ title, links = [], icon, url, active }) {
  const [showLinks, setShowLinks] = useState(active);
  return (
    <li className="pointer">
      {url ? (
        <NavLink to={url}>
          <FontAwesomeIcon icon={icon} />
          {title}
        </NavLink>
      ) : (
        <>
          <button
            className={`nav-link button-link ${active ? 'active' : ''}`}
            type="button"
            onClick={() => setShowLinks(!showLinks)}
          >
            <FontAwesomeIcon icon={icon} />
            {title}
          </button>

          {showLinks && (
            <ul className="sub-links f0-8">
              {links.map((link) => (
                <li key={shortid.generate()}>
                  <NavLink to={link.url} activeClassName="active-link">
                    {link.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </li>
  );
}

NavGroup.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array,
  icon: PropTypes.any,
  url: PropTypes.string,
  active: PropTypes.bool.isRequired,
};

NavGroup.defaultProps = {
  links: [],
  icon: null,
  url: '',
};

export default NavGroup;
