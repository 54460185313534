import {
  SELLERS_LIST,
  GET_SELLER,
  LIST_GALLERY,
  DELETE_GALLERY,
  LIST_WORKSTORE,
  GET_POST,
  LIST_PROMOS,
  GET_PROMO,
  SET_PROMO_PROFILE,
  LIST_BUYERS,
  SET_BUYER,
  LIST_ONGOING_JOBS,
  SET_ONGOING_JOB,
  LIST_GIFT_CARDS,
  SET_GIFT_CARD,
  SET_SELLER_REVIEWS,
  SET_BUYER_REVIEWS,
  GET_CURRENCY,
  GET_CURRENCY_RATES,
  UPDATE_CURRENCY_RATE,
  LIST_PAYMENTS,
  SET_PAYMENT,
  LIST_CASHOUTS,
  SET_TRANSACTION,
  LIST_DISCOUNT_CODES,
  GET_DISCOUNT_CODE,
  LIST_USERS,
  LIST_KEYWORDS,
  SET_BUYER_RECENTLY_VISITED,
} from "./action-types";
import Seller from "../model/Seller";
import Service from "../model/Service";
import StorePost from "../model/StorePost";
import Buyer from "../model/Buyer";
import DiscountCode from "../model/DiscountCode";
import Promo from "../model/Promo";

const initialState = {
  sellers: {
    data: [],
    meta: {},
  },
  seller: {},
  sellerReviews: {
    all: {},
    pending: {},
  },
  buyers: {
    data: [],
    meta: {},
  },
  buyer: {},
  buyerReviews: {
    all: {},
    pending: {},
  },
  buyerRecent: null,
  workstore: {
    data: [],
    meta: {},
  },
  post: {},
  promo: {},
  promoProfiles: {},
  transactions: {
    ongoing: {},
    ongoingList: {},
  },
  discount: {
    giftCardList: {},
    discountCodes: {},
  },
  currency: {
    list: {},
    rates: {},
  },
  paymentList: {},
  paymentTransactions: {},
};

//Sellers
const listSellers = (state, payload) => {
  const { data, ...meta } = payload;

  const sellers = data.map((seller) => new Seller(seller));

  return { ...state, sellers: { data: sellers, meta } };
};

const getSeller = (state, payload) => {
  const profiles = payload.serviceInfos.map((profile) => new Service(profile));
  return {
    ...state,
    seller: {
      seller: new Seller(payload.seller),
      profiles,
      gallery: state.seller.gallery,
    },
  };
};

const listGallery = (state, payload) => {
  return { ...state, seller: { ...state.seller, gallery: payload } };
};

const deleteGallery = (state, payload) => {
  const newGallery = state.seller.gallery.filter(
    (gallery) => gallery.id !== payload
  );

  return { ...state, seller: { ...state.seller, gallery: newGallery } };
};

const setSellerReviews = (state, payload) => {
  return {
    ...state,
    sellerReviews: { ...state.sellerReviews, [payload.type]: payload },
  };
};

const listWorkStore = (state, payload) => {
  const { data, ...meta } = payload;

  const postModels = data.map((item) => new StorePost(item));

  return { ...state, workstore: { data: postModels, meta } };
};

const getWorkstorePost = (state, payload) => {
  return { ...state, post: new StorePost(payload) };
};

const listPromos = (state, payload) => {
  return { ...state, promos: payload };
};

const setPromo = (state, payload) => {
  const promoData = new Promo(payload.promo);

  return {
    ...state,
    promo: {
      ...state.promo,
      [`promo_${payload.promo.id}`]: { ...payload, promo: promoData },
    },
  };
};

const setPromoProfile = (state, payload) => {
  return {
    ...state,
    promoProfiles: {
      ...state.promoProfiles,
      [`profile_${payload.id}`]: new Service(payload),
    },
  };
};

//CUrrency Reducers

const setCurrency = (state, payload) => {
  return {
    ...state,
    currency: {
      ...state.currency,
      list: { ...state.currency.list, [`currency_${payload.id}`]: payload },
    },
  };
};

const setCurrencyRates = (state, payload) => {
  return {
    ...state,
    currency: {
      ...state.currency,
      rates: payload,
    },
  };
};

const updateCurrencyRate = (state, payload) => {
  const filteredRates = state.currency.rates.data.filter(
    (rate) => rate.id !== payload.id
  );

  return {
    ...state,
    currency: {
      ...state.currency,
      rates: { ...state.currency.rates, data: [payload, ...filteredRates] },
    },
  };
};

//Buyer Reducers

const setBuyers = (state, payload) => {
  const { data = [], ...meta } = payload;
  const buyers = data.map((buyer) => new Buyer(buyer));

  return {
    ...state,
    buyers: { data: buyers, meta },
  };
};

const setBuyer = (state, payload) => {
  return {
    ...state,
    buyer: { ...state.buyer, [payload.id]: new Buyer(payload) },
  };
};

const setBuyerReviews = (state, payload) => {
  return {
    ...state,
    buyerReviews: { ...state.buyerReviews, [payload.type]: payload },
  };
};

const setBuyersRecentlyVisited = (state, payload) => {
  return {
    ...state,
    buyerRecent: payload,
  };
};

//transaction reducers
const listOngoingJobs = (state, payload) => {
  return {
    ...state,
    transactions: { ...state.transactions, ongoing: payload },
  };
};

const setOngoingJob = (state, payload) => {
  return {
    ...state,
    transactions: {
      ...state.transactions,
      ongoingList: {
        ...state.transactions.ongoingList,
        [`job_${payload.id}`]: payload,
      },
    },
  };
};

const listGiftCards = (state, payload) => {
  return {
    ...state,
    discount: { ...state.discount, giftCards: payload },
  };
};

const setGiftCard = (state, payload) => {
  return {
    ...state,
    discount: {
      ...state.discount,
      giftCardList: {
        ...state.discount.giftCardList,
        [`voucher_${payload.id}`]: payload,
      },
    },
  };
};

//PAYMENTS
const listPayments = (state, payload) => {
  return { ...state, payments: payload };
};

const setPayment = (state, payload) => {
  return {
    ...state,
    paymentList: { ...state.paymentList, [payload.id]: payload },
  };
};

const listCashouts = (state, payload) => {
  return {
    ...state,
    cashouts: payload,
  };
};

const setPaymentTransaction = (state, { id, name, ...data }) => {
  const transactionKey = `${id}_${name}`;

  return {
    ...state,
    paymentTransactions: {
      ...state.paymentTransactions,
      [transactionKey]: data,
    },
  };
};

const listDiscountCodes = (state, { data = [], ...meta }) => {
  const discounts = data.map((discount) => new DiscountCode(discount));
  const payload = { data: discounts, ...meta };

  return { ...state, discount: { ...state.discount, discountList: payload } };
};

const setDiscountCode = (state, payload) => {
  const discountStoreKey = `discount_${payload.id}`;
  return {
    ...state,
    discount: {
      ...state.discount,
      discountCodes: {
        ...state.discount.discountCodes,
        [discountStoreKey]: payload,
      },
    },
  };
};

const listUsers = (state, payload) => {
  return { ...state, users: payload };
};

const listKeywords = (state, payload) => {
  return { ...state, keywords: payload };
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case SELLERS_LIST:
      return listSellers(state, payload);
    case GET_SELLER:
      return getSeller(state, payload);
    case SET_SELLER_REVIEWS:
      return setSellerReviews(state, payload);
    case LIST_GALLERY:
      return listGallery(state, payload);
    case DELETE_GALLERY:
      return deleteGallery(state, payload);
    case LIST_WORKSTORE:
      return listWorkStore(state, payload);
    case GET_POST:
      return getWorkstorePost(state, payload);
    case LIST_PROMOS:
      return listPromos(state, payload);
    case GET_PROMO:
      return setPromo(state, payload);
    case SET_PROMO_PROFILE:
      return setPromoProfile(state, payload);
    case LIST_BUYERS:
      return setBuyers(state, payload);
    case SET_BUYER:
      return setBuyer(state, payload);
    case SET_BUYER_REVIEWS:
      return setBuyerReviews(state, payload);
    case SET_BUYER_RECENTLY_VISITED:
      return setBuyersRecentlyVisited(state, payload);
    case LIST_ONGOING_JOBS:
      return listOngoingJobs(state, payload);
    case SET_ONGOING_JOB:
      return setOngoingJob(state, payload);
    case LIST_GIFT_CARDS:
      return listGiftCards(state, payload);
    case SET_GIFT_CARD:
      return setGiftCard(state, payload);
    case GET_CURRENCY:
      return setCurrency(state, payload);
    case GET_CURRENCY_RATES:
      return setCurrencyRates(state, payload);
    case UPDATE_CURRENCY_RATE:
      return updateCurrencyRate(state, payload);
    case LIST_PAYMENTS:
      return listPayments(state, payload);
    case SET_PAYMENT:
      return setPayment(state, payload);
    case LIST_CASHOUTS:
      return listCashouts(state, payload);
    case SET_TRANSACTION:
      return setPaymentTransaction(state, payload);
    case LIST_DISCOUNT_CODES:
      return listDiscountCodes(state, payload);
    case GET_DISCOUNT_CODE:
      return setDiscountCode(state, payload);
    case LIST_USERS:
      return listUsers(state, payload);
    case LIST_KEYWORDS:
      return listKeywords(state, payload);
    default:
      return state;
  }
};

export default reducer;
