// import AdminChat from '../../close/pages/Messaging/traits/AdminChat';
import Chat from '../../close/pages/Messaging/traits/Chat';

class Socket {
  constructor(socket) {
    this.socket = socket;
    socket.on('connect', this.connect);
    socket.on('disconnect', this.disconnect);
    socket.on('error', this.error);
    console.log('Socket initiated');
  }

  on = (type, callback) => {
    this.socket.on(type, callback);
  };

  emit = (type, data) => {
    this.socket.emit(type, data);
  };

  connect = () => {
    const chat = new Chat();
    // const adminChat = new AdminChat();

    chat.getAllConversations();
    // chat.getAllSupports();
    // chat.getAdminConversations();
    // adminChat.getAllAdminChat();
    // adminChat.getAdminChat();

    console.log('Connected to socket');
  };

  disconnect = () => {
    console.log('Disconnected from socket');
  };

  error = (err) => {};
}

export default Socket;
