import { lazy } from "react";
import { NavigationTitles } from "./constants";

const ClosedModule = lazy(() => import("./close.module"));
const createPromo = lazy(() => import("./pages/Promos/create"));
const promoList = lazy(() => import("./pages/Promos"));
const promo = lazy(() => import("./pages/Promos/promo"));
const editPromo = lazy(() => import("./pages/Promos/promo/edit"));
const conversations = lazy(() => import("./pages/Messaging/conversations"));
const message = lazy(() => import("./pages/Messaging/conversations/messages"));
const chats = lazy(() =>
  import("./pages/Messaging/conversations/messages/myMessages")
);
const chat = lazy(() =>
  import("./pages/Messaging/conversations/messages/myMessages/messages")
);
const quoteFinder = lazy(() => import("./pages/Messaging/quote-finder"));
const onlineUsers = lazy(() =>
  import("./pages/Messaging/conversations/onlineUsers")
);
const support = lazy(() => import("./pages/Messaging/support"));
const flaggedMessages = lazy(() => import("../open/pages/messages/flagged"));
const BigDataSearch = lazy(() => import("./pages/bigData"));

const otherRoutes = [
  {
    path: "/backend/promo/create",
    exact: true,
    component: createPromo,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title: NavigationTitles.promostore,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/promo",
    exact: true,
    component: promoList,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title: NavigationTitles.promostore,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/promo/:id",
    exact: true,
    component: promo,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title: NavigationTitles.promostore,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/promo/edit/:id",
    exact: true,
    component: editPromo,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/messages/conversations",
    exact: true,
    component: conversations,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/messages/flagged",
    exact: true,
    component: flaggedMessages,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/messages/online-users",
    exact: true,
    component: onlineUsers,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/messages/conversations/:id",
    exact: true,
    component: message,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/messages/supports",
    exact: true,
    component: support,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },

  {
    path: "/backend/messages/quote-finder",
    exact: true,
    component: quoteFinder,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/messages/chat/",
    exact: true,
    component: chats,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/messages/chat/:id",
    exact: true,
    component: chat,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.messaging,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/seller-data-search",
    exact: true,
    component: BigDataSearch,
    permissions: [
      "Admin",
      "Customer_Support",
      "Marketing",
      "Operations",
      "SuperAdmin",
      "Utility",
    ],
    title: NavigationTitles.sellers,
    module: ClosedModule,
    auth: true,
  },
];

export default otherRoutes;
