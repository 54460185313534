export const SELLERS_LIST = "SELLERS_LIST";
export const GET_SELLER = "GET_SELLER";
export const LIST_GALLERY = "LIST_GALLERY";
export const DELETE_GALLERY = "DELETE_GALLERY";
export const LIST_WORKSTORE = "LIST_WORKSTORE";
export const GET_POST = "GET_POST";
export const LIST_PROMOS = "LIST_PROMOS";
export const GET_PROMO = "GET_PROMO";
export const SET_PROMO_PROFILE = "SET_PROMO_PROFILE";
export const SET_SELLER_REVIEWS = "SET_SELLER_REVIEWS";

//Buyers actions
export const LIST_BUYERS = "LIST_BUYERS";
export const SET_BUYER = "SET_BUYER";
export const SET_BUYER_REVIEWS = "SET_BUYER_REVIEWS";
export const SET_BUYER_RECENTLY_VISITED = "SET_BUYER_RECENTLY_VISITED";

//Transactions
export const LIST_ONGOING_JOBS = "LIST_ONGOING_JOBS";
export const SET_ONGOING_JOB = "SET_ONGOING_JOB";

//Gift Cards
export const LIST_GIFT_CARDS = "LIST_GIFT_CARDS";
export const SET_GIFT_CARD = "SET_GIFT_CARD";

//CURRENCY
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_RATES = "GET_CURRENCY_RATES";
export const LIST_NET_CONVERSIONS = "LIST_NET_CONVERSIONS";
export const UPDATE_CURRENCY_RATE = "UPDATE_CURRENCY_RATE";

//Payments
export const LIST_PAYMENTS = "LIST_PAYMENTS";
export const SET_PAYMENT = "SET_PAYMENT";
export const LIST_CASHOUTS = "LIST_CASHOUTS";
export const SET_TRANSACTION = "SET_TRANSACTION";

//Discount codes
export const LIST_DISCOUNT_CODES = "LIST_DISCOUNT_CODES";
export const GET_DISCOUNT_CODE = "GET_DISCOUNT_CODE";

//Users
export const LIST_USERS = "LIST_USERS";

//Keywords
export const LIST_KEYWORDS = "LIST_KEYWORDS";
