import { lazy } from 'react';
import { NavigationTitles } from '../constants';

const ClosedModule = lazy(() => import('../close.module'));
const GiftCards = lazy(() => import('../pages/GiftCards/'));
const Voucher = lazy(() => import('../pages/GiftCards/voucher'));
const CreateGiftCard = lazy(() => import('../pages/GiftCards/create'));

const discounts = lazy(() => import('../pages/discount'));
const createDiscount = lazy(() => import('../pages/discount/create'));
const showDiscount = lazy(() => import('../pages/discount/show'));
const title = NavigationTitles.discounts;

export default [
  {
    path: '/backend/gift-cards',
    exact: true,
    component: GiftCards,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/gift-cards/create',
    exact: true,
    component: CreateGiftCard,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/gift-cards/:id',
    exact: true,
    component: Voucher,
    permissions: ['Admin', 'SuperAdmin'],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/discount/',
    exact: true,
    component: discounts,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/discount/create',
    exact: true,
    component: createDiscount,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: '/backend/discount/:id',
    exact: true,
    component: showDiscount,
    permissions: [
      'Admin',
      'Customer_Support',
      'Marketing',
      'Operations',
      'SuperAdmin',
    ],
    title,
    module: ClosedModule,
    auth: true,
  },
];
