import {
  ADD_MESSAGE,
  LIST_ADMIN_CHATS,
  LIST_CHAT_MESSAGES,
  UPDATE_ADMIN_CONVERSATION,
} from "./action";

const initialState = {
  adminChats: [],
  messages: {},
};

const listAdminChats = (state, payload) => {
  const adminChats = payload.convs.sort((a, b) => b.timestamp - a.timestamp);

  return { ...state, adminChats };
};

const updateConversation = (state, payload) => {
  let updatingConv;
  const otherConvs = state.adminChats?.filter((conv) => {
    if (conv.id === payload.id) {
      updatingConv = conv;
    }
    return conv.id !== payload.id;
  });

  return {
    ...state,
    adminChats: [{ ...updatingConv, ...payload }, ...otherConvs],
  };
};

const listChatMessages = (state, payload) => {
  const sortedMessages = payload?.messages?.sort(
    (a, b) => a.timestamp - b.timestamp
  );

  return {
    ...state,
    messages: { ...state.messages, [payload.conversationId]: sortedMessages },
  };
};

const addMessage = (state, payload) => {
  let newMessages;
  if (state.messages[payload.conversationId]) {
    const convMessages = state.messages[payload.conversationId];
    const filteredMessages = convMessages.filter(
      (message) => message.PK !== payload.PK
    );

    newMessages = [...filteredMessages, payload];
  } else {
    newMessages = [payload];
  }

  return {
    ...state,
    messages: { ...state.messages, [payload.conversationId]: newMessages },
  };
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_ADMIN_CHATS:
      return listAdminChats(state, payload);
    case LIST_CHAT_MESSAGES:
      return listChatMessages(state, payload);
    case ADD_MESSAGE:
      return addMessage(state, payload);
    case UPDATE_ADMIN_CONVERSATION:
      return updateConversation(state, payload);
    default:
      return state;
  }
};

export default reducer;
