import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/provider';
import { ToastProvider } from '@chakra-ui/toast';

//import components
import './assets/scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import { authCheck } from './modules/auth/store/actions';
import Auth from './utils/auth';
import API from './utils/Http';
import { theme } from './theme';
import { QueryClientProvider } from 'react-query';
import queryClient from './utils/queryClient';

(function checkAuth() {
  const auth = new Auth();
  const authenticated = auth.isAuthenticated();
  if (authenticated) {
    API.defaults.headers.common.Authorization = `Bearer ${auth.getUserToken(
      'idToken'
    )}`;
  }
  store.dispatch(authCheck(authenticated));
})();

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <ToastProvider />
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </QueryClientProvider>
  </ChakraProvider>
);
