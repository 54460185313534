import { lazy } from "react";
import { NavigationTitles } from "../constants";

const ClosedModule = lazy(() => import("../close.module"));

const users = lazy(() => import("../pages/users"));
const createUser = lazy(() => import("../pages/users/create"));

const title = NavigationTitles.users;

const userRoutes = [
  {
    path: "/backend/users",
    exact: true,
    component: users,
    permissions: ["SuperAdmin"],
    title,
    module: ClosedModule,
    auth: true,
  },
  {
    path: "/backend/users/create",
    exact: true,
    component: createUser,
    permissions: ["SuperAdmin"],
    title,
    module: ClosedModule,
    auth: true,
  },
];

export default userRoutes;
