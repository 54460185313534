import { v4 as uuidv4 } from "uuid";
import store from "../../../../../store";
import Notify from "../../../../../utils/notify";
import { updateMessages } from "../../../../settings/store/actions";

const {
  GET_MESSAGES,
  GET_MY_CONVERSATIONS,
  GET_CONVERSATIONS,
  GET_ALL_SUPPORTS,
  GET_ADMIN_MESSAGES,
  CREATE_GROUP_CONVERSATION,
  GET_ERROR_LOGS,
  ADD_PARTICIPANT,
  DELETE_LOG,
} = require("../../../../settings/chat/actions");
// const store =

class Chat {
  constructor() {
    const { settings, auth } = store.getState();
    this.socket = settings.socket;
    this.chatName = auth.user.chatName;
    this.userType = "admin";
    this.dispatch = store.dispatch;
  }

  getAllConversations = () => {
    const msgStruct = {
      action: GET_CONVERSATIONS,
      status: "ok",
    };

    this.socket.emit(msgStruct.action, msgStruct);
  };

  getAllSupports = () => {
    const msgStruct = {
      action: GET_ALL_SUPPORTS,
      status: "ok",
    };

    this.socket.emit(msgStruct.action, msgStruct);
  };

  getAdminMessages = (conversationId) => {
    const msgStruct = {
      action: GET_ADMIN_MESSAGES,
      conversationId,
    };

    this.socket.emit(msgStruct.action, msgStruct);
  };

  getMessages = (conversationId) => {
    const msgStruct = {
      action: GET_MESSAGES,
      conversationId,
    };

    this.socket.emit(msgStruct.action, msgStruct);
  };

  acceptSupport = async (data, success) => {
    const msgStruct = {
      ...data,
      action: "adminIntervene",
      supportId: data.id,
      userName: this.chatName,
      userType: this.userType,
    };

    await this.socket.emit(msgStruct.action, msgStruct);
    success && success();
  };

  getAdminConversations = () => {
    this.socket.emit(GET_MY_CONVERSATIONS);
  };

  createGroupConversation = (user, type) => {
    const { auth } = store.getState();
    const msgStruct = {
      action: CREATE_GROUP_CONVERSATION,
      title: auth.user.chatName,
      participants: [
        {
          type: "Admin",
          name: auth.user.chatName,
          id: auth.user.cognitoUsername,
        },
        { type, name: user.username, id: user.id },
      ],
      owner: auth.user.chatName,
    };

    this.socket.emit(msgStruct.action, msgStruct);
  };

  addParticipant = (user, type, userId, conversationId) => {
    const msgStruct = {
      action: ADD_PARTICIPANT,
      participant: { name: user, type, id: userId },
      conversationId,
    };

    Notify.success(`${user} added to conversation`);
    this.socket.emit(msgStruct.action, msgStruct);
  };

  sendMessage = async ({ conversation, message }) => {
    const { auth } = store.getState();

    const msgStruct = {
      PK: uuidv4(),
      conversationId: conversation.conversationId,
      flagged: false,
      passedViolation: true,
      senderName: auth.user.chatName,
      senderId: auth.user.cognitoUsername,
      senderType: "Admin",
      timestamp: Date.now(),
      message: message,
      messageType: "message",
      action: "sendMessage",
      receiverName: conversation.members,
      receiverType: ["buyer", "seller"],
    };

    await this.socket.emit(msgStruct.action, msgStruct);
    this.dispatch(updateMessages(msgStruct));
  };

  getLogs = () => {
    const msgStruct = {
      action: GET_ERROR_LOGS,
      status: "ok",
    };

    this.socket.emit(msgStruct.action, msgStruct);
  };

  deleteLog = (id) => {
    const msgStruct = {
      action: DELETE_LOG,
      id,
    };
    this.socket.emit(msgStruct.action, msgStruct);
  };
}

export default Chat;
