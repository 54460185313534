import { lazy } from 'react';
import { NavigationTitles } from '../constants';

const FullTimeModule = lazy(() => import('../full-time.module'));
const talents = lazy(() => import('../pages/talents'));
const talentSearch = lazy(() => import('../pages/talents/search'));
const folders = lazy(() => import('../pages/talents/folders'));
const folder = lazy(() => import('../pages/talents/folders/[id]'));
const talentId = lazy(() => import('../pages/talents/[id]'));

const sectionTitle = NavigationTitles.talents;

const routes = [
  {
    path: '/fulltime/talents',
    exact: true,
    component: talents,
    permissions: [],
    title: sectionTitle,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/talents/search',
    exact: true,
    component: talentSearch,
    permissions: [],
    title: sectionTitle,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/talents/:id',
    exact: true,
    component: talentId,
    permissions: [],
    title: sectionTitle,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/folders',
    exact: true,
    component: folders,
    permissions: [],
    title: sectionTitle,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/folders/:id',
    exact: true,
    component: folder,
    permissions: [],
    title: sectionTitle,
    module: FullTimeModule,
    auth: true,
  },
];

export default routes;
