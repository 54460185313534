export const LIST_ONGOING_WORKS = 'LIST_ONGOING_WORKS';
export const LIST_ORDERS = 'LIST_ORDERS';

export const listOngoingWorks = (payload) => ({
  type: LIST_ONGOING_WORKS,
  payload,
});

export const listOrders = (payload) => ({
  type: LIST_ORDERS,
  payload,
});
