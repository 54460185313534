import { lazy } from 'react';
import { NavigationTitles } from '../constants';
import FullTimeModule from '../full-time.module';

const jobRequests = lazy(() => import('../pages/jobRequests'));
const jobRequest = lazy(() => import('../pages/jobRequests/[id]'));
const jobPosts = lazy(() => import('../pages/jobPosts'));
const jobPost = lazy(() => import('../pages/jobPosts/[id]'));

const createJobPost = lazy(() => import('../pages/jobPosts/create'));

const routes = [
  {
    path: '/fulltime/job-requests',
    exact: true,
    component: jobRequests,
    permissions: [],
    title: NavigationTitles.jobRequests,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/job-requests/:id',
    exact: true,
    component: jobRequest,
    permissions: [],
    title: NavigationTitles.jobRequests,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/job-posts',
    exact: true,
    component: jobPosts,
    permissions: [],
    title: NavigationTitles.jobPosts,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/job-posts/:id',
    exact: true,
    component: jobPost,
    permissions: [],
    title: NavigationTitles.jobPosts,
    module: FullTimeModule,
    auth: true,
  },
  {
    path: '/fulltime/create-job-post',
    exact: true,
    component: createJobPost,
    permissions: [],
    title: NavigationTitles.jobPosts,
    module: FullTimeModule,
    auth: true,
  },
];

export default routes;

const formPayload = {
  clientId: 760000839,
  clientName: 'John Doe',
  companyAddress: '123 Main St',
  companyName: 'ABC Company',
  aboutCompany:
    'We are a leading tech company specializing in software development.',
  companyWebsite: 'www.abccompany.com',
  industry: 'Technology',
  jobTitle: 'Software Engineer',
  jobField: 'Web Development',
  description:
    'We are looking for a skilled software engineer to join our team.',
  responsibilities: 'Develop and maintain web applications.',
  jobSkills: ['React', 'Node.js', 'MongoDB'],
  languages: 'English',
  certifications: ['CCNA', 'CCNP'],
  minimumQualification: 'Masters',
  workLocation: 'Lagos, Nigeria',
  jobType: 'Full-time',
  workMode: 'Remote',
  applicationDeadline: '2024-2-15',
  expectedNoOfHire: '2',
  rightToWork: 'Yes',
  minSalary: '50000',
  maxSalary: '70000',
  currencyCode: 'USD',
  countryCode: 'US',
  otherBenefits: 'Health insurance, paid time off',
};

const formPayloadSnake = {
  client_id: 760000839,
  client_name: 'John Doe',
  company_address: '123 Main St',
  company_name: 'ABC Company',
  about_company:
    'We are a leading tech company specializing in software development.',
  company_website: 'www.abccompany.com',
  industry: 'Technology',
  job_title: 'Software Engineer',
  job_field: 'Web Development',
  description:
    'We are looking for a skilled software engineer to join our team.',
  responsibilities: 'Develop and maintain web applications.',
  job_skills: ['React', 'Node.js', 'MongoDB'],
  languages: 'English',
  certifications: ['CCNA', 'CCNP'],
  minimum_qualification: 'Masters',
  work_location: 'Lagos, Nigeria',
  job_type: 'Full-time',
  work_mode: 'Remote',
  application_deadline: '2024-2-15',
  expected_no_of_hire: '2',
  right_to_work: 'Yes',
  min_salary: '50000',
  max_salary: '70000',
  currency_code: 'USD',
  country_code: 'US',
  other_benefits: 'Health insurance, paid time off',
};
