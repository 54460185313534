import {
  faUserCog,
  faUserTie,
  faStore,
  faTags,
  faComment,
  faAmbulance,
  faBriefcase,
  faPercent,
  faFunnelDollar,
  faCreditCard,
  faUser,
  faShoppingCart,
  faMoneyBillAlt,
  faAddressCard,
} from '@fortawesome/free-solid-svg-icons';

export const NavigationTitles = {
  sellers: 'Talents',
  profiles: 'Seller Profiles',
  buyers: 'Buyers',
  workstore: 'WorkStore',
  employ: 'Employ',
  promostore: 'PromoStore',
  messaging: 'Messaging',
  payments: 'Payments',
  marketplace: 'Marketplace',
  transactions: 'Transactions',
  discounts: 'Discounts',
  users: 'Users',
  developers: 'Developers',
  finances: 'Finances',
};

export const navItems = [
  {
    title: NavigationTitles.sellers,
    links: [
      {
        title: 'All Sellers',
        url: '/backend/sellers',
      },
      {
        title: 'Pending',
        url: '/backend/sellers/pending',
      },
      {
        title: 'Public',
        url: '/backend/sellers/public',
      },
      {
        title: 'Non Public',
        url: '/backend/sellers/not-public',
      },
      {
        title: 'Non Verified',
        url: '/backend/sellers/not-verified',
      },
      {
        title: 'Sellers Review',
        url: '/backend/sellers-reviews',
      },
      {
        title: 'Seller Summary',
        url: '/backend/sellers-summary',
      },
      {
        title: 'Skill by Services',
        url: '/backend/skill-services',
      },
      {
        title: 'Seller Vitals',
        url: '/backend/sellers-vitals',
      },
      {
        title: 'Seller Screening',
        url: '/backend/sellers-screening',
      },
      {
        title: 'Data Search',
        url: '/backend/seller-data-search',
      },
      {
        title: 'Report',
        url: '/backend/sellers/report',
      },
    ],
    icon: faUserCog,
  },
  {
    title: NavigationTitles.profiles,
    links: [
      {
        title: 'All Profiles',
        url: '/backend/seller-profiles',
      },
      {
        title: 'Pending',
        url: '/backend/seller-profiles/pending',
      },
      {
        title: 'Live',
        url: '/backend/seller-profiles/live',
      },
      {
        title: 'Non-Live',
        url: '/backend/seller-profiles/non_live',
      },
      {
        title: 'Profile Collection',
        url: '/backend/profile-collection',
      },
      {
        title: 'Report',
        url: '/backend/seller-profiles/report',
      },
    ],
    icon: faShoppingCart,
  },
  {
    title: NavigationTitles.buyers,
    links: [
      {
        title: 'All Buyers',
        url: '/backend/buyers',
      },
      {
        title: 'Buyers Reviews',
        url: '/backend/buyers-reviews',
      },
      {
        title: 'Recently Visited',
        url: '/backend/buyer-recent',
      },
    ],
    icon: faUserTie,
  },
  {
    title: NavigationTitles.workstore,
    links: [
      {
        title: 'WorkStore',
        url: '/backend/workstore',
      },
      {
        title: 'Pending',
        url: '/backend/workstore/pending',
      },
      {
        title: 'Open Jobs',
        url: '/backend/workstore/open',
      },
    ],
    icon: faStore,
  },
  {
    title: NavigationTitles.employ,
    links: [
      {
        title: 'Jobs',
        url: '/backend/employ',
      },
    ],
    icon: faAddressCard,
  },
  {
    title: NavigationTitles.promostore,
    links: [
      {
        title: 'Create',
        url: '/backend/promo/create',
      },
      {
        title: 'Promos',
        url: '/backend/promo',
      },
      {
        title: 'Ongoing',
        url: '/backend/promo/ongoing',
      },
      {
        title: 'Ended',
        url: '/backend/promo/ended',
      },
    ],
    icon: faTags,
  },
  {
    title: NavigationTitles.messaging,
    links: [
      {
        title: 'Admin Chat',
        url: '/backend/messages/admin-chat',
      },
      {
        title: 'User Conversations',
        url: '/backend/messages/conversations',
      },
      {
        title: 'My Conversations',
        url: '/backend/messages/chat',
      },
      {
        title: 'Support Requests',
        url: '/backend/messages/supports',
      },
      {
        title: 'Online Users',
        url: '/backend/messages/online-users',
      },
      {
        title: 'Flagged Messages',
        url: '/backend/messages/flagged',
      },
      {
        title: 'Profanity Checker',
        url: '/backend/messages/profanity-checker',
      },
      {
        title: 'Broadcasts',
        url: '/backend/messages/broadcasts',
      },
      {
        title: 'Quote Finder',
        url: '/backend/messages/quote-finder',
      },
    ],
    icon: faComment,
  },
  {
    title: NavigationTitles.payments,
    links: [
      {
        title: 'Payment History',
        url: '/backend/payments',
      },
      {
        title: 'Pending CashOuts',
        url: '/backend/payments/cashouts',
      },
      {
        title: 'Top-up',
        url: '/backend/payments/topup',
      },
      {
        title: 'CashOut Charges',
        url: '/backend/payments/cashout-charges',
      },
      {
        title: 'Currency',
        url: '/backend/currency',
      },
      {
        title: 'Currency Converter',
        url: '/backend/currency/converter',
      },
      {
        title: 'Currency Rates',
        url: '/backend/currency/rates',
      },
      {
        title: 'Net Conversion',
        url: '/backend/currency/net-conversions',
      },
    ],
    icon: faCreditCard,
  },
  {
    title: NavigationTitles.marketplace,
    links: [
      {
        title: 'Affiliates',
        url: '/backend/affiliates',
      },
      {
        title: 'Services',
        url: '/backend/services',
      },
      {
        title: 'Categories',
        url: '/backend/categories',
      },
      {
        title: 'Waiting Periods',
        url: '/backend/services/waiting-periods',
      },
      {
        title: 'Job Titles',
        url: '/backend/job-titles',
      },
      {
        title: 'Commission Matrix',
        url: '/backend/commission',
      },
      {
        title: 'Search Keywords',
        url: '/backend/search-keywords',
      },
      {
        title: 'Seller Tags',
        url: '/backend/tags/seller',
      },
      {
        title: 'Seller Profile Tags',
        url: '/backend/tags/profile',
      },
      {
        title: 'Banks',
        url: '/backend/banks',
      },
    ],
    icon: faFunnelDollar,
  },

  {
    title: NavigationTitles.transactions,
    links: [
      {
        title: 'Ongoing Jobs',
        url: '/backend/work/ongoing/',
      },
      {
        title: 'Submitted',
        url: '/backend/work/ongoing/submitted/',
      },
      {
        title: 'Accepted',
        url: '/backend/work/ongoing/accepted/',
      },
      {
        title: 'Revision',
        url: '/backend/work/ongoing/revision/',
      },
      {
        title: 'Resolution',
        url: '/backend/work/ongoing/resolution/',
      },
      {
        title: 'Escalated',
        url: '/backend/work/ongoing/escalated',
      },
      {
        title: 'Pending Orders',
        url: '/backend/orders/pending',
      },
    ],
    icon: faBriefcase,
  },
  {
    title: NavigationTitles.discounts,
    links: [
      {
        title: 'Gift Cards',
        url: '/backend/gift-cards/',
      },
      {
        title: 'Discount Codes',
        url: '/backend/discount/',
      },
    ],
    icon: faPercent,
  },
  {
    title: NavigationTitles.finances,
    links: [
      {
        title: 'Sales Report',
        url: '/backend/finance/report',
      },
      {
        title: 'Wallet balances',
        url: '/backend/finance/wallets',
      },
      {
        title: 'Total Balances',
        url: '/backend/finance/balances',
      },
      {
        title: 'Volume Report',
        url: '/backend/finance/volume-report',
      },
    ],
    icon: faMoneyBillAlt,
  },
  {
    title: NavigationTitles.users,
    links: [
      {
        title: 'Users',
        url: '/backend/users',
      },
    ],
    icon: faUser,
  },
  {
    title: NavigationTitles.developers,
    links: [
      {
        title: 'Chat Logs',
        url: '/backend/developers/chat/logs',
      },
    ],
    icon: faAmbulance,
  },
];

export const VerificationStatus = {
  PENDING: 1,
  VERIFIED: 10,
  DISABLED: 0,
};

export const AFFILIATE_STATUS = {
  PENDING: 1,
  APPROVED: 10,
  DECLINED: 0,
};

export const VerificationText = {
  1: 'Pending',
  10: 'Verified',
  0: 'Deactivated',
};

export const AnnotationsPrefixes = {
  interview: {
    title: 'Interview',
    prefixes: [
      'CSTN GREAT',
      'CSTN AVERAGE',
      'CSTN BAD',
      'INTN GREAT',
      'INTN AVERAGE',
      'INTN BAD',
      'EXTN GREAT',
      'EXTN AVERAGE',
      'EXTN BAD',
    ],
  },
  skill: {
    title: 'Skills/Validation',
    prefixes: ['SSCIL', 'HSCIL', 'T3RDP'],
  },
  project: {
    title: 'Project & Customer',
    prefixes: ['PRJTYP', 'CSTYP'],
  },
  general: {
    title: 'General',
    prefixes: ['AKTYP', 'LCTN'],
  },
  partners: {
    title: 'Partners',
    prefixes: ['GDA'],
  },
};
