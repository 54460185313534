import { TalentTypes } from '../constant';
import { GET_TALENTS, GET_TALENT_PROFILES } from './actionTypes';

const initialState = {
  [TalentTypes.summary]: null,
  screening: null,
};

const listTalents = (state, payload = {}) => {
  return { ...state, [payload.type]: payload };
};

const listTalentProfiles = (state, payload) => {
  return { ...state, [payload.type]: payload };
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TALENTS:
      return listTalents(state, payload);
    case GET_TALENT_PROFILES:
      return listTalentProfiles(state, payload);
    default:
      return state;
  }
};
