export const SET_BALANCES = 'SET_BALANCES';
export const SET_VOLUME_REPORT = 'SET_VOLUME_REPORT';
export const SET_WALLETS = 'SET_WALLETS';
export const SET_ESCROWED = 'SET_ESCROWED';
export const setBalances = (payload) => ({
  type: SET_BALANCES,
  payload,
});

export const setWallets = (payload) => ({
  type: SET_WALLETS,
  payload,
});

export const setVolumeReport = (payload) => ({
  type: SET_VOLUME_REPORT,
  payload,
});

export const setEscrowed = (payload) => ({
  type: SET_ESCROWED,
  payload,
});
