import { QueryClient } from 'react-query';

export const QueryKeys = {
  buyer: 'buyer',
  talent: 'talent',
  jobRequests: 'jobRequests',
  jobRequest: 'jobRequest',
  jobPosts: 'jobPosts',
  jobPost: 'jobPost',
  folders: 'folders',
  annotations: 'annotations',
  talentStats: 'talentStats',
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000,
    },
  },
});

export default queryClient;
